<template>
  <div class="login-container">
    <div class="welcome">
      <h1>Welcome!</h1>
      <h1>Let's check it on.</h1>
    </div>
    <div class="image-upload-container">
      <div class="edit-icon" @click="triggerFileInput">
        <img class="edit-image" src="@/assets/edit.png" />
      </div>
      <label for="image-upload" class="image-upload-label">
        <img v-if="imageData" :src="imageData" class="user-image" />
        <div v-else class="plus-circle">{{ firstLetter }}</div>
      </label>
      <input id="image-upload" type="file" @change="handleImageUpload" ref="fileInput" style="display: none" />
    </div>
    <div class="input-container">
      <input class="input-username" v-model="username" type="text" placeholder="Username" />
      <button @click="confirm">Confirm</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

const route = useRoute();
const router = useRouter();
const username = ref('');
const telegramId = ref('');
const imageData = ref('');
const fileInput = ref(null);
const selectedFile = ref(null); // Добавляем переменную для файла
const firstLetter = ref('');
const invited_by = ref('');

// Смотрим за изменениями в инпуте и обновляем значение firstLetter
watch(username, () => {
  if (username.value) {
    firstLetter.value = username.value.charAt(0).toUpperCase();
  } else {
    firstLetter.value = '';
  }
});

onMounted(() => {
  if (route.query.username) {
    username.value = route.query.username;
  }
  if (route.query.telegram_id) {
    telegramId.value = route.query.telegram_id;
  }
  if (route.query.invited_by) {
    invited_by.value = route.query.invited_by;
  }
});

const handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedFile.value = file; // Сохраняем файл
    const reader = new FileReader();
    reader.onload = (e) => {
      imageData.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
};

const confirm = async () => {
  try {
    const formData = new FormData();
    formData.append('username', username.value);
    formData.append('telegram_id', telegramId.value);
    if (selectedFile.value) {
      formData.append('photo', selectedFile.value);
    }
    formData.append('invited_by', invited_by.value);

    console.log('FormData:', formData); // Логируем данные перед отправкой

    // Вызов API для добавления нового пользователя с изображением
    const response = await axios.post('/api/add_user', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Response:', response); // Логируем ответ сервера
    // Перенаправление на страницу аккаунта
    router.push(`/main`);
  } catch (error) {
    console.error('There was an error adding the user:', error);
  }
};

// Функция для программного открытия окна выбора файла
const triggerFileInput = () => {
  fileInput.value.click();
};
</script>

<style scoped>

.edit-image {
  width: 23px;
  height: 23px;
}

.edit-icon {
  position: absolute;
  top: 10px;
  right: -3px;
  background: #adadad;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: Arial;
  font-weight: 600;
  color: #f0f8ff;
}

.image-upload-label {
  display: flex;
}

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.image-upload-container {
  position: relative;
  width: 110px;
  height: 110px;
  background-color: #6f6f6f;
  margin-bottom: 76px;
  border: 2px solid #fff;
  cursor: pointer;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.plus-circle {
  font-size: 30px;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-image {
  width: 109px;
  height: 109px;
  object-fit: cover;
  border-radius: 50%;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

h1 {
  margin: 0px 0px 10px 0px;
  font-family: Arial;
  color: #e5e5e5;
}

.input-container {
  display: flex;
  align-items: center;
}

.input-username {
  border: none;
  border-bottom: 2px solid orange;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  outline: none;
  background: none;
  color: aliceblue;
}

button {
  background-color: orange;
  border: none;
  padding: 10px 33px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 7px;
}
</style>
