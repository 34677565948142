<template>
  <div class="container">
  <div class="league-container">
    <div class="leaderboard">
      <!-- Блюренное изображение -->
      <div class="blurred-trophy" :style="{ backgroundImage: `url(${leagueImagePathBG})` }"></div>
      
      <div class="trophy-and-arrows">

        <!-- Левая стрелка -->
        <img 
          class="arrow-left-league" 
          :src="require('@/assets/left-arrow.png')" 
          @click="changeLeague('prev')" 
          alt="Previous League" 
        />
        <!-- Оригинальное изображение -->
        <img class="trophy" :src="leagueImagePath" />
        <!-- Правая стрелка -->
        <img 
          class="arrow-right-league" 
          :src="require('@/assets/right-arrow.png')" 
          @click="changeLeague('next')" 
          alt="Next League" 
        />

      </div>
      
      <p class="league-name">{{ getLocalizedLeague }}</p>
      
      <!-- Добавленный hr элемент для отображения прогресса -->
      <div class="progress-bar">
        <div class="progress" :style="progressStyle"></div>
      </div>

      <div class="user-points">
        <img class="coinleague" :src="require('@/assets/leagueCoin.png')" /> 
        {{ formatNumber(points) }} / {{ formatNumber(maxCoins) }}
      </div>
    </div>
    <div class="participants">
      <div 
        v-for="(participant, index) in participants" 
        :key="`participant-${leagueName}-${participant.id}`" 
        class="participant-header" 
        :style="{ backgroundColor: participant.username === queryUsername ? '#171717F2' : participant.color, paddingLeft: participant.username === queryUsername ? '7px' : '10px' }"
      >
        <div class="participant-avatar-container">
          <img 
            :src="participant.photo ? `data:image/png;base64,${participant.photo}` : require('@/assets/user-pic.png')" 
            class="participant-avatar" 
            :style="{ border: participant.username === queryUsername ? '3px solid var(--Native-link_color, #2990FF)' : '' }" 
            alt="User Photo" 
          />
        </div>
        <div class="info-container">
          <div class="participant-info">
            <h1 class="participant-name">
              {{ participant.username }}
              <span v-if="participant.username === queryUsername" class="username-circle"></span>
            </h1>
            <!-- Отображаем место пользователя, добавив +1 к индексу, потому что индексация начинается с 0 -->
            <div class="participant-points">
              <img class="coin" :src="require('@/assets/refCoin.png')" alt="Coin" />
              {{ formatNumber(participant.coins) }}
            </div>
          </div>
          <div class="participant-place">{{ index + 1 }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { getLocalizedText } from '@/localizatoin';


const route = useRoute();
const router = useRouter();
const leagueName = ref('Bronze');
const coins = ref(0);
const maxCoins = ref(0);
const points = ref(0);
const participants = ref([]);
const queryUsername = route.query.username;

const getLocalizedLeague = computed(() => {
  return getLocalizedText(`league_${leagueName.value}`)
})

const leagueLoc = computed(() => {
  return getLocalizedText(`league`)
})

const leagues = ['Bronze', 'Silver', 'Gold', 'Platinum'];

function formatNumber(number) {
  return number.toLocaleString('en-US');
}

const goToMain = () => {
  router.push({ name: 'MainPage' });
};

const fetchLeaderboardData = async (telegramId) => {
  try {
    const response = await axios.get(`/api/leaderboard/${telegramId}`);
    if (response.data) {
      points.value = response.data.coins;
    } else {
      console.error('No data found in response:', response.data);
    }
  } catch (error) {
    console.error('Error fetching leaderboard data:', error);
  }
};

const progressStyle = computed(() => {
  const progressPercentage = (points.value / maxCoins.value) * 100;
  return { background: `linear-gradient(to right, white 0%, white ${progressPercentage}%, transparent ${progressPercentage}%)` };
});

const fetchLeagueInfo = async (leagueId) => {
  try {
    const response = await axios.get(`/api/league_info?league_id=${leagueId}`);
    if (response.data) {
      leagueName.value = response.data.leagueName;
      coins.value = response.data.coins;
      maxCoins.value = response.data.maxCoins;
      participants.value = response.data.participants;
    }
  } catch (error) {
    console.error('Error fetching league info:', error);
  }
};

const changeLeague = (direction) => {
  const currentIndex = leagues.indexOf(leagueName.value);
  let newIndex;

  if (direction === 'prev') {
    newIndex = (currentIndex - 1 + leagues.length) % leagues.length;
  } else {
    newIndex = (currentIndex + 1) % leagues.length;
  }

  const newLeague = leagues[newIndex];
  router.push({ name: 'LeaguePage', query: { league_id: newIndex + 1, username: queryUsername } });
};

watch(() => route.query.league_id, async (newLeagueId) => {
  await fetchLeagueInfo(newLeagueId);
  const telegramId = localStorage.getItem('telegram_id');
  await fetchLeaderboardData(telegramId);
});

onMounted(async () => {
  window.Telegram.WebApp.ready();

  // Показываем кнопку "Назад"
  window.Telegram.WebApp.BackButton.show();

  // Обрабатываем клик по кнопке "Назад"
  window.Telegram.WebApp.BackButton.onClick(() => {
    // Действие при нажатии на кнопку "Назад"
    // Например, возврат на предыдущий экран
    router.push('/discovery');   // Если хотите просто вернуться на предыдущую страницу
    // Или любое другое действие, которое вам нужно
  });
  const leagueId = route.query.league_id;
  const telegramId = localStorage.getItem('telegram_id');
  await fetchLeagueInfo(leagueId);
  await fetchLeaderboardData(telegramId);
});

const leagueImagePath = computed(() => {
  return require(`@/assets/${leagueName.value}.png`);
});

const leagueImagePathBG = computed(() => {
  return require(`@/assets/BG-${leagueName.value}.png`);
});
</script>

<style scoped>

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: calc(100dvh + 1px);  
  overflow: hidden;
}

.trophy-and-arrows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.arrow-left-league {
  margin-right: 38.5px;
}

.arrow-right-league {
  margin-left: 38.5px;
}

.arrow-left-league, .arrow-right-league {
  height: 24px;
  cursor: pointer;
  z-index: 1112;
  margin-top: 39px;
}

.username-circle {
  display: inline-block;
  margin-left: 8px; /* Или любое другое значение для отступа */
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--Native-link_color, #2990FF);
}

.participant-place {
  margin-right: 16px;
  color: var(--Native-hint_color, #AAAAAA);
  font-size: 17px;
  font-weight: 400;
}

.info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.progress-bar {
  width: 236px;
  height: 2px;
  background-color: transparent;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  background: var(--Custom-outline, #FFFFFF1A);
  margin-top: 16px;
  margin-bottom: 16px;
}

.blurred-trophy {
  position: absolute;
  top: 0;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  width: 250px;
  height: 250px;
  background-size: cover;
  /* background-position: 50%; */
  z-index: 0;
}

.progress {
  height: 100%;
  background-color: white; /* Фон теперь белый */
  border-radius: 4px;
}

.user-points {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 400;
  margin-top: 16px;
  color: aliceblue;
  gap: 8px;
}

.league-name {
  font-size: 24px;
  font-weight: 400;
  margin-top: 16px;
}

.coinleague {
  width: 22px;
  height: 22px;
}

.trophy {
  width: 111px;
  height: 111px;
  z-index: 1;
  margin-top: 39px;
}

.league-container, .leaderboard, .participants {
    width: 100%;
}

.league-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.participants {
  box-shadow: inset 0 16px 18px 0 #00000057;
  width: 100%;
  background: var(--Native-bg_color, #212121);
  flex-grow: 1;
  overflow-y: auto;
  height: auto;
  padding: 10px 0;
  margin-top: 106px;
  overflow-x: hidden;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.participant-header {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0px;
    padding: 10px;
}

.participant-header h1 {
  color: aliceblue;
  font-size: 20px;
}

.participant-avatar-container {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    flex-shrink: 0;
}

.participant-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.participant-name {
  font-size: 17px;
  font-weight: 510;
  display: flex;
  align-items: center;
}

.coin {
  width: 13px;
}

.participant-points {
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-weight: bold;
  color: aliceblue;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: var(--Native-subtitle_text_color, #AAAAAA);
  justify-content: flex-start;
  align-items: center;
}

.participant-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.participant-info h1 {
  font-weight: 100;
}

p, h1, .participant-points {
  margin: 0;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.go-back {
    position: absolute;
    left: 0;
    width: 30px;
    margin-left: 10px;
    margin-top: 35px;
}

p {
    font-size: 20px;
    color: #f0f8ff;
    margin-bottom: 10px;
    font-weight: 600;   
}

.leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
}

.coin {
    width: 20px;
    height: 20px;
}

</style>
