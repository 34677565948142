<template>
  <div>
    <div class="container">
      <div id="map"></div>
      <div class="places-container">
        <div class="places" v-for="place in placesRef" :key="place.id">
          <div class="place-container">
            <div class="place-image" :style="`background-image: url(${getImageSrc(place.category)})`"></div>
            <div class="place-info">
              <div class="last-checkin">{{ place.last_checkin }}</div>
              <div class="place-name">{{ place.name }}</div>
              <div class="place-address">{{ place.address }}</div>
              <div class="place-checkins">{{ place.count }} {{ $t('check_ins') }}</div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

interface Place {
  id: string;
  name: string;
  category: string;
  latitude: number;
  longitude: number;
  count: number;
  last_checkin: string;
  address: string;
}

export default defineComponent({
  name: 'VisitedPlaces',
  setup() {
    const placesRef = ref<Place[]>([]);
    const address = ref('');
    let map: any;
    const router = useRouter();

    const initMap = (token: string) => {
      console.log('Initializing MapKit with token:', token);
      if (!window.mapkit) {
        console.error('MapKit is not loaded');
        return;
      }

      window.mapkit.init({
        authorizationCallback: (done: (arg0: any) => void) => {
          done(token);
        },
        language: 'ru'
      });

      map = new window.mapkit.Map("map", {
        showsCompass: window.mapkit.FeatureVisibility.Hidden,
        showsZoomControl: window.mapkit.FeatureVisibility.Visible,
        showsMapTypeControl: window.mapkit.FeatureVisibility.Hidden,
        showsScale: window.mapkit.FeatureVisibility.Hidden,
        mapType: window.mapkit.Map.MapTypes.Standard,
        colorScheme: window.mapkit.Map.ColorSchemes.Dark,
        isZoomEnabled: true,
        isScrollEnabled: true
      });

      var factory = function(coordinate: any, options: { data: { count: string | null; }; }) {
          var div = document.createElement("div");
          div.textContent = options.data.count;
          div.className = "custom-cluster";
          return div;
      };


      map.annotationForCluster = function(cluster: { memberAnnotations: string | any[]; coordinate: any; }) {
          console.log('Creating cluster annotation for:', cluster);

          var options = {
              data: { count: cluster.memberAnnotations.length }
          };
          var clusterAnnotation = new window.mapkit.Annotation(cluster.coordinate, factory, options);

          return clusterAnnotation;
      };


      return map;
    };


    const addAnnotations = (places: any[]) => {
      if (!map) {
        console.error('Map is not initialized');
        return;
      }

      console.log('Adding annotations:', places);

      const annotations = places.map(place => {
        const coordinate = new window.mapkit.Coordinate(place.latitude, place.longitude);
        const annotation = new window.mapkit.ImageAnnotation(coordinate, {
          url: {1: '/assets/single-pin.png'},
          title: place.name,
          subtitle: place.category,
          clusteringIdentifier: 'placeCluster',
          clusterRadius: 50
        });
        return annotation;
      });

      map.removeAnnotations(map.annotations);
      map.addAnnotations(annotations);
      setTimeout(() => {
        map.showItems(annotations); // Отображение аннотаций на карте
      }, 0); // Убедимся, что аннотации полностью добавлены перед вызовом showItems()
    };

    const fetchPlaces = async (telegramId: string) => {
      try {
        console.log('Fetching places for telegram ID:', telegramId);
        const response = await axios.post(`/api/user-places`, {
          telegram_id: telegramId
        });
        placesRef.value = response.data.places;

        placesRef.value.sort((a, b) => new Date(b.last_checkin).getTime() - new Date(a.last_checkin).getTime());

        placesRef.value.forEach(place => {
          const date = new Date(place.last_checkin);
          const options = { day: '2-digit', month: 'long' } as const;
          const userLang = localStorage.getItem('user_language');
          if (userLang == 'ru') {
            place.last_checkin = date.toLocaleDateString('ru-RU', options)
          } else {
            place.last_checkin = date.toLocaleDateString('en-US', options);
          }
        });

        addAnnotations(placesRef.value);
      } catch (error) {
        console.error('Error fetching places:', error);
      }
    };

    const getImageSrc = (category: string) => {
      return require(`@/assets/${category.toLowerCase()}.png`);
    };

    onMounted(async () => {
      
      window.Telegram.WebApp.ready();

      // Показываем кнопку "Назад"
      window.Telegram.WebApp.BackButton.show();

      // Обрабатываем клик по кнопке "Назад"
      window.Telegram.WebApp.BackButton.onClick(() => {
        router.push('/discovery');  
      });


      const telegramId = localStorage.getItem('telegram_id');
      if (!telegramId) {
        console.error('No telegram_id found in local storage');
        return;
      }

      try {
        const response = await fetch('/get-mapkit-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();
        const token = data.token;
        initMap(token);
        await fetchPlaces(telegramId);
      } catch (error) {
        console.error('Error during map initialization:', error);
      }

      // Перехватываем и останавливаем события прокрутки
      const mapElement = document.getElementById('map');
      if (mapElement) {
        mapElement.addEventListener('touchstart', (e) => {
          e.stopPropagation();
          preventCollapse();
        });
        mapElement.addEventListener('touchmove', (e) => {
          e.stopPropagation();
        });
        mapElement.addEventListener('touchend', (e) => {
          e.stopPropagation();
        });
      }

      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.disableVerticalSwipes();
      }

      function ensureDocumentIsScrollable() {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
          document.documentElement.style.setProperty("height", "calc(100vh + 1px)", "important");
        }
      }

      function preventCollapse() {
        if (window.scrollY === 0) {
          window.scrollTo(0, 1);
        }
      }

      window.addEventListener("load", ensureDocumentIsScrollable);
    });

    return {
      placesRef,
      getImageSrc,
      address
    };
  }
});
</script>

<style scoped>

.place-address {
  color: var(--Native-subtitle_text_color, #AAAAAA);
  font-size: 13px;
  font-weight: 400;
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

hr {
  width: 100%;
  border: 0.5px solid var(--Custom-divider, #FFFFFF0D);
  margin-bottom: 0;
}

.place-checkins {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 400;
  color: var(--Native-hint_color, #AAAAAA);
}

.place-image {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: 50%;
  margin-left: 16px;
  margin-bottom: 10px;
}

.place-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.place-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.last-checkin {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 400;
  color: var(--Native-subtitle_text_color, #AAAAAA);
}

#map {
    width: 90%;
    height: 250px;
    border-radius: 15px; /* Скругленные углы */
    overflow: hidden;
    touch-action: none; /* Отключаем жесты прокрутки */
}

.places{
  width: 98%;
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 65px;
  /* margin-bottom: 12px; */
  border-radius: 10px;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
  height: auto;
}

.places-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 95px;
  justify-content: center;
  width: 90%;
  background: var(--Native-bg_color, #212121);
  border-radius: 12px;
  height: auto;
}

.place-name {
    font-size: 18px;
    font-weight: 510;
}

.place-category {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 600;
}

</style>
