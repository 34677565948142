<template>
  <div :class="['task', { completed: taskCompleted }]" @click="handleTaskClick">
    <div class="task-container">
      <img class="task-image" :src="getTaskImage" alt="task" />
      <div class="task-info-container">
        <div class="task-info">
          <h3 class="task-name">{{ getLocalizedName }}</h3>
          <p class="task-effect">{{ getLocalizedEffect }}</p>
          <div class="price-container">
            <img v-if="!taskCompleted" class="coin" src="@/assets/leagueCoin.png" alt="Coin" />
            <p v-if="taskCompleted" class="task-price">{{ completed }}</p>
            <p v-else class="task-price">{{ task.reward }}</p>
          </div>
        </div>
        <img v-if="!loading && !showClaimButton && !taskCompleted" class="stroke-img" src="@/assets/strokeGrey.png" alt="stroke" />
        <div v-if="loading" class="loading-ring"></div>
        <button v-if="showClaimButton" class="claim" @click.stop.prevent="claimReward">{{ claim }}</button>
        <img v-if="taskCompleted" src="@/assets/checked.png" class="checked" alt="Completed" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, computed, ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { getLocalizedText } from '@/localizatoin';

interface Task {
  id: number;
  name: string;
  description: string;
  reward: number;
  link: string;
  completed: boolean;
}

const props = defineProps<{
  task: Task;
}>();

const getTaskImage = computed(() => {
  return require('@/assets/task.png');
});
const completed = computed(() => {
  return getLocalizedText('completed');
})

const claim = computed(() => {
  return getLocalizedText('claim');
})

const taskCompleted = ref(props.task.completed);
const loading = ref(false);
const showClaimButton = ref(false);

const getLocalizedName = computed(() => {
  if (props.task.name === 'Follow us on X') {
        return getLocalizedText('task_name_x');
      } else if (props.task.name === 'Follow us on Telegram') {
        return getLocalizedText('task_name_tg');
      } else {
        // Если тип буста не распознан, используем оригинальное описание
        return props.task.name;
      }
})

const getLocalizedEffect = computed(() => {
  if (props.task.name === 'Follow us on X') {
        return getLocalizedText('task_name_x_effect');
      } else if (props.task.name === 'Follow us on Telegram') {
        return getLocalizedText('task_name_tg_effect');
      } else {
        // Если тип буста не распознан, используем оригинальное описание
        return props.task.description;
      }
})

const claimReward = async () => {
  try {
    const telegramId = localStorage.getItem('telegram_id');
    if (!telegramId) {
      console.error('Telegram ID not found in local storage');
      return;
    }

    await axios.get('/api/get-reward', {
      params: {
        reward: props.task.reward,
        telegram_id: telegramId,
        task_id: props.task.id
      }
    });

    taskCompleted.value = true;
    showClaimButton.value = false;  // Скрыть кнопку "Claim"
    loading.value = false;  // Убрать загрузочное кольцо, если оно ещё активно
    sessionStorage.setItem(`task_${props.task.id}_completed`, 'true');
  } catch (error) {
    console.error('Error claiming reward:', error);
  }
};

const handleTaskClick = async () => {
  // Если задача уже завершена, действия не требуются
  if (taskCompleted.value) {
    return;
  }

  // Если кнопка "Claim" видима, вызываем функцию claimReward
  if (showClaimButton.value) {
    await claimReward();
    return;
  }

  // Если кнопка "Claim" не видима и загрузка не идет, начинаем загрузку и показываем кнопку
  if (!loading.value) {
    loading.value = true; // Активируем загрузочное кольцо
    sessionStorage.setItem(`task_${props.task.id}_loading`, 'true');
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(props.task.link);
    } else {
      window.open(props.task.link, '_blank');
    }

    // Задержка перед отображением кнопки "Claim"
    setTimeout(() => {
      loading.value = false;
      showClaimButton.value = true; // Показываем кнопку "Claim" после 10 секунд
      sessionStorage.setItem(`task_${props.task.id}_loading`, 'false');
      sessionStorage.setItem(`task_${props.task.id}_showClaimButton`, 'true');
    }, 10000);
  }
};

// Восстановление состояния при загрузке страницы
const restoreState = () => {
  if (sessionStorage.getItem(`task_${props.task.id}_completed`) === 'true') {
    taskCompleted.value = true;
  }

  if (sessionStorage.getItem(`task_${props.task.id}_loading`) === 'true') {
    loading.value = true;
  }

  if (sessionStorage.getItem(`task_${props.task.id}_showClaimButton`) === 'true') {
    showClaimButton.value = true;
  }
};

// Восстановление состояния при загрузке страницы
restoreState();

watch(() => document.visibilityState, (newState) => {
  if (newState === 'visible') {
    restoreState();
  }
});
</script>

  
<style scoped>

.claim{
  color: var(--Native-subtitle_text_color, #AAAAAA);
  background: none;
  font-size: 13px;
  font-weight: 400;
  border: none;
  width: 50px;
  height: 100%;
}

.completed {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checked {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.loading-ring {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  width: 24px;
  height: 24px;
  min-width: 24px; 
  min-height: 24px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
  
.task-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
  
  .stroke-img {
    margin-right: 21px;
    width: 5px;
    height: 10px;
  }
  
  .task-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 16px;
  }
  
  .task-image {
    width: 74px;
    height: 74px;
  }
  
  .task-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  
  .task-price {
    font-size: 17px;
    margin: 0px;
    font-weight: 400;
    color: var(--Native-text_color, #FFFFFF);
  }
  
  .task-name {
    margin: 0px;
    font-size: 17px;
    color: var(--Native-text_color, #FFFFFF);
    font-weight: 510;
    margin-top: 12px;
  }
  
  .task-effect {
    margin: 0px;
    font-size: 14px;
    color: var(--Native-hint_color, #AAAAAA);
    font-weight: 400;
    max-width: 202px;
  }
  
  .task {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: var(--Native-bg_color, #212121);
    color: #f0f8ff;
    width: 85%;
    border-radius: 16px;
    height: auto;
    padding: 0px 16px;
    margin: 0px 16px 20px 16px;
  }
  
  .price-container {
    color: var(--Native-text_color, #FFFFFF);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 8px;
  }
  
  .coin {
    width: 20px;
    height: 20px;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
</style>
  