<template>
  <div :style="{ width: containerWidth }" ref="scrollContainer" class="scroll-container">
    <PlaceCard
      v-for="item in places"
      :key="item.id"
      :imageSrc="getImageSrc(item.category)"
      :name="item.name"
      :address="item.address"
      :checkins="item.checkins"
      @checkIn="openModal(item)"
      :timeLeft="timeLeft"
      :loading="loading"
      :distance="item.distance"
      />
  </div>
  <MakeCheckIn
      v-if="selectedPlace"
      :selectedPlace="selectedPlace"
      :loading="loading"
      :showResult="showResult"
      :result="result"
      :selectedRate="selectedRate"
      :closeModal="closeModal"
      :ratePlace="ratePlace"
      :checkIn="checkIn"
      :returnHome="returnHome"
      :message="message"
    />
</template>

<script lang="ts" setup>
import { ref, computed, defineProps } from 'vue';
import axios from 'axios';
import PlaceCard from './PlaceCard.vue';
import MakeCheckIn from './MakeCheckIn.vue';
import router from '@/router';

interface Place {
  distance: any;
  id: string;
  name: string;
  category: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  url: string;
  address: string;
  checkins: number; // Добавьте это поле
}

const props = defineProps<{
  places: Place[];
  timeLeft: number;
  loading: boolean;
}>();

const elementWidth = 163; // ширина одного элемента
const gap = 12; // расстояние между элементами

const containerWidth = computed(() => {
  return `${props.places.length * (elementWidth + gap) - gap}px`;
});


const getImageSrc = (category: string) => {
  return require(`@/assets/${category.toLowerCase()}.png`);
};

const selectedPlace = ref<Place | null>(null);
const loading = ref(false);
const showResult = ref(false);
const result = ref({
  streak: 0,
  coins: 0,
  bonusCoins: 0,
  leagueMultiplier: 0,
  streakBonus: 0,
  reviewBonus: 0,
  totalMultiplier: 0,
  firstCheckinBonus: 0,
  placeCheckinBonus: 0,
  boost: 0,
  isMayor: false,
  checkinsCount: 0,
  isNewMayor: false,
  previousMayorNickname: '',
  currentMayorNickname: '',
});
const selectedRate = ref<number | null>(null);
const message = ref('');

const openModal = (place: Place) => {
  selectedPlace.value = place;
  showResult.value = false;
  selectedRate.value = null;
  result.value = {
    streak: 0,
    coins: 0,
    bonusCoins: 0,
    leagueMultiplier: 0,
    streakBonus: 0,
    reviewBonus: 0,
    totalMultiplier: 0,
    firstCheckinBonus: 0,
    placeCheckinBonus: 0,
    boost: 0,
    isMayor: false,
    checkinsCount: 0,
    isNewMayor: false,
    previousMayorNickname: '',
    currentMayorNickname: '',
  };
};

const closeModal = () => {
  selectedPlace.value = null;
};

const ratePlace = (rate: number) => {
  selectedRate.value = rate;
};

const checkIn = async () => {
  if (selectedPlace.value) {
    const telegramId = localStorage.getItem('telegram_id');
    if (!telegramId) {
      console.error('Telegram ID not found in local storage');
      return;
    }

    loading.value = true;
    message.value = '';

    try {
      const response = await axios.post('/checkin', {
        telegram_id: telegramId,
        place_name: selectedPlace.value.name,
        category: selectedPlace.value.category,
        latitude: selectedPlace.value.latitude,
        longitude: selectedPlace.value.longitude,
        rate: selectedRate.value || undefined,
        address: selectedPlace.value.address
      });

      const { streak, coins, bonusCoins, leagueMultiplier, streakBonus, reviewBonus, totalMultiplier, firstCheckinBonus, placeCheckinBonus, boost, isMayor, checkinsCount, isNewMayor, previousMayorNickname, currentMayorNickname } = response.data;

      result.value = {
        streak,
        coins,
        bonusCoins,
        leagueMultiplier,
        streakBonus,
        reviewBonus,
        totalMultiplier,
        firstCheckinBonus,
        placeCheckinBonus,
        boost,
        isMayor,
        checkinsCount,
        isNewMayor,
        previousMayorNickname,
        currentMayorNickname,
      };

      console.log('Result:', result.value);

      showResult.value = true;
      message.value = 'Check-in successful';
    } catch (error) {
      console.error('Error during check-in:', error);
      message.value = 'Check-in failed';
    } finally {
      loading.value = false;
    }
  }
};

const returnHome = () => {
  selectedPlace.value = null;
  `router.push({ name: 'Main' }).then(() => {
    window.location.reload();
  });`
  window.location.reload();
};

</script>

<style scoped>


.scroll-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  gap: 12px;
}

.scroll-container::-webkit-scrollbar {
  height: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
