<template>
  <div class="user-header">
    <img
      class="user-avatar"
      :src="userPhoto"
      alt="User Photo"
    />
    <h1 class="user-name">{{ username }}</h1>
  </div>
  <div class="buttons">
    <router-link 
      to="/referrals"
      class="referrals-button"
    >
      My referrals
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

export default defineComponent({
  setup() {
    const username = ref('');
    const userPhoto = ref('');
    const route = useRoute();
    const router = useRouter();

    const fetchUserData = async (telegramId: string) => {
      try {
        const response = await axios.get(`/api/get_username?telegram_id=${telegramId}`);
        if (response.data && response.data.username) {
          username.value = response.data.username;
          userPhoto.value = response.data.photo ? `data:image/png;base64,${response.data.photo}` : '';
          const userData = {
            username: response.data.username,
            photo: response.data.photo,
          };
          sessionStorage.setItem(`user_${telegramId}`, JSON.stringify(userData));
        } else {
          console.error('Username not found in response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    onMounted(async () => {
      let telegramId = route.query.telegram_id as string;

      if (!telegramId) {
        telegramId = localStorage.getItem('telegram_id') || '';
        if (!telegramId) {
          console.error('No telegram_id found');
          return;
        } else {
          router.replace({ query: { ...route.query, telegram_id: telegramId } });
        }
      } else {
        localStorage.setItem('telegram_id', telegramId);
      }

      const cachedUserData = sessionStorage.getItem(`user_${telegramId}`);
      if (cachedUserData) {
        const userData = JSON.parse(cachedUserData);
        username.value = userData.username;
        userPhoto.value = userData.photo ? `data:image/png;base64,${userData.photo}` : '';
      } else {
        await fetchUserData(telegramId);
      }
    });

    return {
      username,
      userPhoto,
    };
  }
});
</script>
<style scoped>

.buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.referrals-button {
  background-color: #e7640b;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%; 
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.user-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px 0px 10px;
}

.user-header h1 {
  color: aliceblue;
}

.user-avatar {
  border-radius: 50%;
  margin-right: 20px;
  width: 60px;
  height: 60px; /* Добавляем высоту для квадратного контейнера */
  object-fit: cover; /* Масштабируем изображение, чтобы оно заполнило контейнер, сохраняя пропорции */
  object-position: center; /* Центрируем изображение внутри контейнера */
}

.user-name {
  font-size: 24px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
</style>
