import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import UserProfile from "../views/UserProfile.vue";
import ReferralsView from "../views/ReferralsView.vue";
import MainPage from "../views/MainPage.vue";
import LoginPage from "../views/LoginPage.vue"
import BoostPage from "../views/BoostPage.vue";
import LeaguePage from "../views/LeaguePage.vue";
import CheckinComponent from "@/views/CheckinComponent.vue";
import VisitedPlaces from "@/views/VisitedPlaces.vue";
import Discovery from "@/views/Discovery.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/discovery",
  },
  {
    path: "/checkin",
    name: "Checkin",
    component: CheckinComponent
  },
  {
    path: "/account",
    name: "Account",
    component: UserProfile
  },
  {
    path: "/boost",
    name: "Boost",
    component: BoostPage
  },
  {
    path: "/main",
    name: "Main",
    component: MainPage
  },
  {
    path: "/referrals",
    name: "Referrals",
    component: ReferralsView
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage
  },
  {
    path: '/league',
    name: 'LeaguePage',
    component: LeaguePage,
  },
  {
    path: '/visited',
    name: 'Visited',
    component: VisitedPlaces
  },
  {
    path: '/discovery',
    name: 'Discovery',
    component: Discovery
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
