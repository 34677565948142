import { inject, Ref, ref, onMounted } from 'vue';
import { TonConnectUI, TonConnectUiOptions } from '@tonconnect/ui';

export function useTonConnectUI() {
  const tonConnectUI = inject<Ref<TonConnectUI | null>>('TonConnectUI', ref(null));

  if (!tonConnectUI || !tonConnectUI.value) {
    console.error("TonConnectUI is not provided.");
  }

  return {
    setOptions: (options: TonConnectUiOptions) => {
      if (tonConnectUI && tonConnectUI.value) {
        tonConnectUI.value.uiOptions = options;
      }
    },
    tonConnectUI  // Возвращаем также сам объект, если нужно использовать напрямую
  };
}
