<template>
  <div class="container">
    <div>
      <TonConnectButton />
    </div>
  <div class="boost-page">
    <p class="title">{{ $t('boost') }}</p>
    <div class="user-coins">
      <p class="coins-title">{{ $t('your_balance') }}</p>
      <img class="coin" :src="require('@/assets/leagueCoin.png')" alt="Coin" />
      <p class="coins">{{ coins }}</p>
    </div>
  </div>
  <div class="boost-container">
    <div class="boosts">
      <BoostComponent 
        v-for="boost in boosts" 
        :key="boost.id"
        :boost="boost"
        :nextLevelPrice="calculateNextLevelPrice(boost.first_level_price, boost.currentLevel)"
        @open-modal="openModal(boost)"
      />
    </div>
  </div>
  <div class="tasks-component">
    <p class="tasks-component-text">SPECIAL MISSIONS</p>
    <div class="tasks">
      <TaskComponent
        v-for="task in tasks"
        :key="task.id"
        :task="task"
      />
    </div>
  </div>

  <BoostModal 
    v-if="selectedBoost" 
    :boost="selectedBoost" 
    :is-visible="isModalVisible"
    :next-level-price="calculateNextLevelPrice(selectedBoost.first_level_price, selectedBoost.currentLevel)"
    @close-modal="closeModal"
    @buy-boost="handleBuyBoost"
  />
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import BoostComponent from '@/components/BoostComponent.vue';
import BoostModal from '@/components/BoostModal.vue';
import TaskComponent from '@/components/TaskComponent.vue';
import TonConnectButton from '@/components/TonConnectButton.vue';

interface Boost {
  id: number;
  name: string;
  first_level_price: number;
  description: string;
  max_level: number;
  currentLevel: number;
  effect: string;
}

interface Task {
  id: number;
  name: string;
  link: string;
  reward: number;
  description: string;
  completed: boolean;
}

interface UserBoost {
  id_user: number;
  id_boost: number;
  current_level: number;
}

export default defineComponent({
  name: 'BoostPage',
  components: {
    BoostComponent,
    BoostModal,
    TaskComponent,
    TonConnectButton
  },
  setup() {

    const router = useRouter();
    const boosts = ref<Boost[]>([]);
    const selectedBoost = ref<Boost | null>(null);
    const isModalVisible = ref(false);
    const coins = ref<number>(0);
    const tasks = ref<Task[]>([]);

    const goToMain = () => {
      router.push({ name: 'MainPage' });
    };

    const fetchBoosts = async () => {
      const telegramId = localStorage.getItem('telegram_id');
      if (!telegramId) {
        console.error('Telegram ID not found in local storage');
        return;
      }

      try {
        const response = await axios.get('/api/boosts', { params: { telegram_id: telegramId } });
        const allBoosts: Boost[] = response.data.allBoosts;
        const userBoosts: UserBoost[] = response.data.userBoosts;

        boosts.value = allBoosts.map((boost) => {
          const userBoost = userBoosts.find((ub) => ub.id_boost === boost.id);
          return {
            ...boost,
            currentLevel: userBoost ? userBoost.current_level : 0,
            effect: boost.description,
          };
        });

        await fetchLeaderboardData(telegramId); // Fetch coins data and store in local storage
      } catch (error) {
        console.error('Error fetching boosts:', error);
      }
    };

    const fetchTasks = async () => {
      const telegramId = localStorage.getItem('telegram_id');
      if (!telegramId) {
        console.error('Telegram ID not found in local storage');
        return;
      }

      try {
        const response = await axios.get('/api/tasks', { params: { telegram_id: telegramId } });
        tasks.value = response.data;
        console.log('Fetched tasks:', tasks.value); // Логирование задач
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    const fetchLeaderboardData = async (telegramId: string) => {
      try {
        const response = await axios.get('/api/user_coins', {
          params: { telegram_id: telegramId }
        });
        if (response.data) {
          coins.value = response.data.coins;
          localStorage.setItem('coins', response.data.coins.toString());
        } else {
          console.error('No data found in response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    };

    const calculateNextLevelPrice = (firstLevelPrice: number, currentLevel: number) => {
      return firstLevelPrice * Math.pow(2, currentLevel);
    };

    const openModal = (boost: Boost) => {
      selectedBoost.value = boost;
      isModalVisible.value = true;
    };

    const closeModal = () => {
      selectedBoost.value = null;
      isModalVisible.value = false;
    };

    const handleBuyBoost = async (boost: Boost) => {
      const telegramId = localStorage.getItem('telegram_id');
      if (!telegramId) {
        console.error('Telegram ID not found in local storage');
        return;
      }

      const nextLevelPrice = calculateNextLevelPrice(boost.first_level_price, boost.currentLevel);

      if (boost.currentLevel < boost.max_level && coins.value >= nextLevelPrice) {
        try {
          let apiUrl = '';
          if (boost.name === 'Check-in timer') {
            apiUrl = '/api/buy_time_boost';
          } else if (boost.name === 'Traveller') {
            apiUrl = '/api/buy_bonus_boost';
          }

          await axios.post(apiUrl, {
            telegram_id: telegramId,
            boost_id: boost.id,
            new_level: boost.currentLevel + 1,
          });

          coins.value -= nextLevelPrice;
          localStorage.setItem('coins', coins.value.toString()); // Update coins in local storage

          boost.currentLevel += 1;
          boost.effect = boost.description;

          // Обновляем данные о монетах в локальном хранилище
          const response = await axios.get(`/api/user_coins`, {
            params: {
              telegram_id: telegramId,
            },
          });
          if (response.data && response.data.coins !== undefined) {
            localStorage.setItem('coins', response.data.coins.toString());
            coins.value = response.data.coins;
          } else {
            console.error('No data found in response:', response.data);
          }
          
          closeModal(); // Close the modal after successful purchase
        } catch (error) {
          console.error('Error updating boost:', error);
        }
      } else {
        console.error('Not enough coins or max level reached');
      }
    };

    onMounted(async() => {
      await fetchBoosts();
      await fetchTasks();

      window.Telegram.WebApp.ready();

      // Показываем кнопку "Назад"
      window.Telegram.WebApp.BackButton.show();

      // Обрабатываем клик по кнопке "Назад"
      window.Telegram.WebApp.BackButton.onClick(() => {
        router.push('/discovery');  
      });

    });

    return {
      boosts,
      selectedBoost,
      isModalVisible,
      coins,
      calculateNextLevelPrice,
      openModal,
      closeModal,
      handleBuyBoost,
      tasks,
      goToMain
    };
  }
});
</script>

<style scoped>

.go-back {
    position: absolute;
    left: 0;
    width: 30px;
    margin-left: 10px;
    margin-top: 10px;
  }

#ton-connect-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
}

.tasks-component-text {
  font-size: 13px;
  font-weight: 400;
  color: var(--Native-section_header_text_color, #AAAAAA);
  margin-left: 32px;
}

.coins-title {
  font-size: 17px;
  font-weight: 400;
  color: var(--Native-hint_color, #AAAAAA);
}

  .user-coins {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .coin {
    width: 20px;
  }

  .coins {
    font-size: 17px;
    font-weight: 400;
    margin: 0;
    color: aliceblue;
  }

  .boost-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 60px;
    padding-top: 55px; 
  }

  .title {
    font-size: 34px;
    font-weight: 400;
    margin: 0px;
    color: var(--Native-text_color, #FFFFFF);

  }

  .tasks-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 80px;
  }

  .boost-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .boost-title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    color: aliceblue;
  }

  .boosts, .tasks {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  </style>
  