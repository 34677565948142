<template>
  <div id="map"></div>
  <div class="places-container">
    <div class="places" v-for="place in placesRef" :key="place.id" @click="openModal(place)">
      <div class="place-name">{{ place.name }}</div>
      <div class="place-category">{{ place.category }}</div>
    </div>
  </div>
  <div v-if="selectedPlace" class="modal" @click="closeModal">
    <div class="modal-content" @click.stop>
      <div class="modal-header" v-if="!loading && !showResult">
        <h2>{{ selectedPlace.name }}</h2>
        <div class="modal-rating">
          <p>Rate this place for extra coins</p>
          <div class="rating-container">
            <img 
              :src="selectedRate === 1 ? require('@/assets/dislike_filled.png') : require('@/assets/dislike.png')" 
              @click="ratePlace(1)"
              class="rating-icon"
              alt="Dislike"
            />
            <img 
              :src="selectedRate === 2 ? require('@/assets/like_filled.png') : require('@/assets/like.png')" 
              @click="ratePlace(2)"
              class="rating-icon"
              alt="Like"
            />
          </div>
        </div>
        <button class="make-checkin" @click="checkIn" :disabled="loading">
          <span v-if="loading" class="loader"></span>
          <span v-else>Check in</span>
        </button>
      </div>
      <div v-else-if="loading" class="loading-container">
        <div class="loader"></div>
      </div>
      <div v-else class="result-container" @click.stop>
        <h2>{{ selectedPlace.name }}</h2>
        <div class="bonus-coins">
          <p>+ {{ result.bonusCoins }}</p>
          <img class="coin" src="@/assets/coin.png" alt="Coin" />
        </div>
        <div class="bonus-info" @click.stop>
          <p class="details">{{ $t('details') }}</p>
          <p>Streak: {{ result.streak }}</p>
          <p v-if="result.placeCheckinBonus !== 0">Check in bonus: + {{ result.placeCheckinBonus }}</p>
          <p v-if="result.firstCheckinBonus !== 0">First check in of the day: + {{ result.firstCheckinBonus }}</p>
          <p v-if="result.leagueMultiplier !== 0">League bonus: x{{ result.leagueMultiplier + 1 }}</p>
          <p v-if="result.streakBonus !== 0">Streak Bonus: x{{ result.streakBonus + 1 }}</p>
          <p v-if="result.reviewBonus !== 0">Review Bonus: x{{ result.reviewBonus + 1 }}</p>
          <p v-if="result.boost !== 0">Paid bonus: x{{ result.boost + 1 }}</p>
        </div>
        <button class="close-button" @click="returnHome">Close</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

interface Place {
  id: string;
  name: string;
  category: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  url: string;
  // Добавьте другие поля, которые могут быть в объектах мест
}

export default defineComponent({
  name: 'MapComponent',
  setup() {

    const placesRef = ref<Place[]>([]);
    const selectedRate = ref<2 | 1 | null>(null);
    const selectedPlace = ref<Place | null>(null);
    const loading = ref(false);
    const showResult = ref(false);
    const message = ref('');
    const router = useRouter();
    const result = ref({
      streak: 0,
      coins: 0,
      bonusCoins: 0,
      leagueMultiplier: 0,
      streakBonus: 0,
      reviewBonus: 0,
      totalMultiplier: 0,
      firstCheckinBonus: 0,
      placeCheckinBonus: 0,
      boost: 0
    });

    onMounted(async () => {
      // Проверка загрузки MapKit
      if (!window.mapkit || !window.mapkit.loadedLibraries || window.mapkit.loadedLibraries.length === 0) {
        await new Promise<void>((resolve) => { window.initMapKit = resolve; });
        delete window.initMapKit;
      }

      // Получение токена для инициализации MapKit
      const response = await fetch('/get-mapkit-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      const token = data.token;

      // Инициализация MapKit
      window.mapkit.init({
        authorizationCallback: (done: (arg0: any) => void) => {
          done(token);
        },
        language: 'ru'
      });

      // Получение текущей геопозиции пользователя
      navigator.geolocation.getCurrentPosition((position) => {
        const userLatitude = position.coords.latitude;
        const userLongitude = position.coords.longitude;

        // Создание карты
        const map = new window.mapkit.Map("map", {
          center: new window.mapkit.Coordinate(userLatitude, userLongitude),
          showsCompass: window.mapkit.FeatureVisibility.Hidden,
          showsZoomControl: window.mapkit.FeatureVisibility.Hidden,
          showsMapTypeControl: window.mapkit.FeatureVisibility.Hidden,
          showsScale: window.mapkit.FeatureVisibility.Hidden,
          mapType: window.mapkit.Map.MapTypes.Standard,
          colorScheme: window.mapkit.Map.ColorSchemes.Dark
        });

        // Установка области видимости карты
        const region = new window.mapkit.CoordinateRegion(
          new window.mapkit.Coordinate(userLatitude, userLongitude),
          new window.mapkit.CoordinateSpan(0.01, 0.01)
        );
        map.setRegionAnimated(region);

        // Добавление аннотации на карту
        const annotation = new window.mapkit.MarkerAnnotation(
          new window.mapkit.Coordinate(userLatitude, userLongitude),
          {
            color: '#ff0000',
            title: 'Вы здесь'
          }
        );
        map.addAnnotation(annotation);

        // Поиск точек интереса в радиусе 50 метров
        const filter = window.mapkit.PointOfInterestFilter.including([
          window.mapkit.PointOfInterestCategory.Cafe,
          window.mapkit.PointOfInterestCategory.Restaurant,
          window.mapkit.PointOfInterestCategory.Nightlife
        ]);

        const search = new window.mapkit.PointsOfInterestSearch({
          center: new window.mapkit.Coordinate(userLatitude, userLongitude),
          radius: 1500,
          pointOfInterestFilter: filter
        });

        search.search((error: any, data: { places: any; }) => {
          if (error) {
            console.error('Error during POI search:', error);
            return;
          }

          // Обработка результатов поиска
          const placesData = data.places;
          placesData.forEach((place: { name: any; pointOfInterestCategory: any; coordinate: { latitude: any; longitude: any; }; phoneNumber: any; url: any; }) => {
            
            placesRef.value = placesData.map((place: { name: any; pointOfInterestCategory: any; coordinate: { latitude: any; longitude: any; }; phoneNumber: any; url: any; }) => ({
            name: place.name,
            category: place.pointOfInterestCategory,
            latitude: place.coordinate.latitude,
            longitude: place.coordinate.longitude,
            phoneNumber: place.phoneNumber,
            url: place.url,
            // Добавьте сюда photo, если API возвращает фото
          }));

            // Добавление меток на карту
            const placeAnnotation = new window.mapkit.MarkerAnnotation(
              new window.mapkit.Coordinate(place.coordinate.latitude, place.coordinate.longitude),
              {
                title: place.name,
                subtitle: place.pointOfInterestCategory
              }
            );
            map.addAnnotation(placeAnnotation);
          });
        });

      }, (error) => {
        console.error('Error getting user location:', error);
      });
    });

    const returnHome = () => {
      selectedPlace.value = null;
      selectedRate.value = null;
      showResult.value = false;
      message.value = '';
      router.push({ name: 'Main' });
    }

    const openModal = (place: Place) => { 
      selectedPlace.value = place;
      showResult.value = false;
      selectedRate.value = null;
      result.value = {
        streak: 0,
        coins: 0,
        bonusCoins: 0,
        leagueMultiplier: 0,
        streakBonus: 0,
        reviewBonus: 0,
        totalMultiplier: 0,
        firstCheckinBonus: 0,
        placeCheckinBonus: 0,
        boost: 0
      };
    };

    const closeModal = () => {
      selectedPlace.value = null;
      selectedRate.value = null;
      showResult.value = false;
      message.value = '';
    };

    const ratePlace = (rate: 2 | 1) => {
      selectedRate.value = rate;
      console.log(`Selected rate: ${rate}`);
    };

    const checkIn = async () => {
      if (selectedPlace.value) {
        const telegramId = localStorage.getItem('telegram_id');
        if (!telegramId) {
          console.error('Telegram ID not found in local storage');
          return;
        }

        loading.value = true;
        message.value = '';

        try {
          const response = await axios.post('/checkin', {
            telegram_id: telegramId,
            place_name: selectedPlace.value.name,
            category: selectedPlace.value.category,
            latitude: selectedPlace.value.latitude,
            longitude: selectedPlace.value.longitude,
            rate: selectedRate.value || undefined
          });

          console.log(response.data);
          const { streak, coins, bonusCoins, leagueMultiplier, streakBonus, reviewBonus, totalMultiplier, firstCheckinBonus, placeCheckinBonus, boost } = response.data;
          
          // Обновляем результат
          result.value = {
            streak,
            coins,
            bonusCoins,
            leagueMultiplier,
            streakBonus,
            reviewBonus,
            totalMultiplier,
            firstCheckinBonus,
            placeCheckinBonus,
            boost
          };

          showResult.value = true;

          message.value = 'Check-in successful';
        } catch (error) {
          console.error('Error during check-in:', error);
          message.value = 'Check-in failed';
        } finally {
          loading.value = false;
        }
      }
    };

    return {
      placesRef,
      selectedPlace,
      selectedRate,
      openModal,
      closeModal,
      ratePlace,
      checkIn,
      loading,
      showResult,
      result,
      message,
      returnHome
    };
  }
});
</script>

<style scoped>

.modal-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.bonus-coins {
  display: flex;
  flex-direction: row;
  font-size: 40px;
  align-items: center;
  gap: 10px;
  margin-top: auto;
}

.bonus-coins img {
  width: 45px;
  height: 45px;
}

h2 {
  margin-top: none;
}

.bonus-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-left: 20px;
  position: relative;
}

.details {
  position: absolute;
  top: 16px;
  left: 16px;
}

.rating-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.bonus-info p {
    margin: 10px;
}

.rating-icon {
  width: 50px;
  height: 50px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.rating-icon.selected {
  transform: scale(1.2);
}

.rating-icon:hover {
  transform: scale(1.1);
}

.modal-rating {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
    background: #444444;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    height: 80%;
    margin-top: auto;
}

#map {
  width: 100%;
  height: 250px;
  border-radius: 15px; /* Скругленные углы */
  overflow: hidden;
}

.result-container {
  display: flex;
  flex-direction: column;
  height: 98%;
  align-items: center;
  gap: 10px;
}

.make-checkin {
  background-color: #ff9a02;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  width: 90%;
  margin-top: auto;
}

.places{
  width: 98%;
  display: flex;
  flex-direction: column;
  background: #ff9a02;
  color: #ffffff;
  height: 65px;
  margin-bottom: 12px;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: center;
}

.places-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 70px;
  justify-content: center;
  width: 100%;
}

.place-name {
    font-size: 18px;
    margin-left: 15px;
}

.place-category {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 600;
}

.rating-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff9a02;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.close-button {
  background-color: #ff9a02;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  width: 90%;
  margin-top: auto;
  height: 40px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center
}

</style>
