<template>
  <div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, provide, ref } from 'vue';
import { TonConnectUI } from '@tonconnect/ui';

export default defineComponent({
  name: 'TonConnectUIProvider',
  setup() {
    const tonConnectUI = ref<TonConnectUI | null>(null);

    onMounted(async () => {
      await nextTick();
      console.log("TonConnectUIProvider mounted");
      try {
        tonConnectUI.value = new TonConnectUI({
          manifestUrl: 'https://checkiton.net/manifest/tonconnect-manifest.json'
        });
        provide('TonConnectUI', tonConnectUI.value);
        console.log("TonConnectUI initialized");
      } catch (error) {
        console.error("Error initializing TonConnectUI:", error);
      }
    });

    provide('TonConnectUI', tonConnectUI);

    return {
      tonConnectUI
    };
  }
});
</script>
