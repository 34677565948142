import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NavigationPanel = _resolveComponent("NavigationPanel")!
  const _component_TonConnectUIProvider = _resolveComponent("TonConnectUIProvider")!

  return (_openBlock(), _createBlock(_component_TonConnectUIProvider, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view),
        (_ctx.showNavigationPanel)
          ? (_openBlock(), _createBlock(_component_NavigationPanel, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}