<template>
  <div v-if="selectedPlace" class="modal" @click="closeModal">
    
    <div class="modal-content" @click.stop>
      <div class="modal-header" v-if="!loading && !showResult">
        <div class="go-back" @click="closeModal">
          <img class="stroke" src="@/assets/stroke.png" alt="Go back" />
        </div>
        <div class="background-image" :style="{ backgroundImage: `url(${getImageSrc(selectedPlace.category)})` }">
        </div>
        <div class="foreground-content">
          <div class="image-container" :style="{ backgroundImage: `url(${getImageSrc(selectedPlace.category)})`, width: '120px', height: '120px' }">
          </div>
          <h2 class="selected-place-name">{{ selectedPlace.name }}</h2>
          <div class="address-container">
            <div class="distance">
              <img class="gps-icon" src="@/assets/gps.png" alt="GPS Icon" />
              {{ selectedPlace.distance }}m
            <p class="selected-place-address">{{ selectedPlace.address }}</p>
          </div>
          </div>
          <p class="selected-place-category">{{ selectedPlace.category }}</p>
        </div>
        <div class="modal-rating">
          <p class="modal-rating-text">{{ $t('rate') }}</p>
          <div class="rating-container">
            <div class="like-container" :style="selectedRate === 1 ? { backgroundColor: '#dddddd3d'} : {}" @click="() => ratePlace(1)">
              <img 
                :src="require('@/assets/rate/fire.png')" 
                class="rating-icon"
                alt="Dislike"
              />
            </div>
            <div class="dislike-container" :style="selectedRate === 2 ? { backgroundColor: '#dddddd3d'} : {}"  @click="() => ratePlace(2)">
              <img 
                :src="require('@/assets/rate/heart.png')" 
                class="rating-icon"
                alt="Like"
              />
            </div>
            <div class="dislike-container" :style="selectedRate === 3 ? { backgroundColor: '#dddddd3d'} : {}"  @click="() => ratePlace(3)">
              <img 
                :src="require('@/assets/rate/poop.png')" 
                class="rating-icon"
                alt="Like"
              />
            </div>
            <div class="dislike-container" :style="selectedRate === 4 ? { backgroundColor: '#dddddd3d'} : {}"  @click="() => ratePlace(4)">
              <img 
                :src="require('@/assets/rate/heze.png')" 
                class="rating-icon"
                alt="Like"
              />
            </div>
          </div>
        </div>
        <button class="make-checkin" @click="checkIn" :disabled="loading">
          <img class="checkin-icon" src="@/assets/geocheckin.png" alt="Checkin" />
          <span v-if="loading" class="loader"></span>
          <span v-else>{{ $t('check_in') }}</span>
        </button>
      </div>
      <div v-else-if="loading" class="loading-container">
        <div class="loader"></div>
      </div>
      <div v-else class="result-container" @click.stop ref="animationContainer">
        <div class="animation">
          <LottieAnimation
            ref="anim"
            :animation-data="ConfettiJSON"
            :loop="true"
            :auto-play="true"
            :speed="1"
            @loopComplete="loopComplete"
            @complete="complete"
            @enterFrame="enterFrame"
            @segmentStart="segmentStart"
            @stopped="stopped"/>
        </div>

        <div class="background-image" :style="{ backgroundImage: `url(${getImageSrc(selectedPlace.category)})` }">
        </div>
        <div class="foreground-content-result">
          <div class="image-container" :style="{ backgroundImage: `url(${getImageSrc(selectedPlace.category)})`}">
          </div>
          <div class="result-info">
            <div class="address-container">
              <h2 class="selected-place-name" id="result-name">{{ selectedPlace.name }}</h2>
              <div class="distance">
                <img class="gps-icon" src="@/assets/gps.png" alt="GPS Icon" />
                {{ selectedPlace.distance }}m
                <p class="selected-place-address">{{ selectedPlace.address }}</p>
              </div>
              <p class="selected-place-category">{{ selectedPlace.category }}</p>
            </div>
          </div>
        </div>


        <div class="checkin-info-container">
          <div class="checkin-info">
          
            <div v-if="result.isMayor" class="mayor-container">
              <div class="mayor-image-container">
                <img class="mayor-image" src="@/assets/mayor/venki.png" alt="Mayor" />
                <div class="circle-account">{{ firstLetter }}</div>
                <img class="layer" src="@/assets/mayor/layer.png" alt="Layer" />
                <img class="crown" src="@/assets/mayor/crown.png" alt="Crown" />
                <img class="stars" src="@/assets/mayor/stars.png" alt="Stars" />
              </div>
              <p class="mayor-message">{{ $t('mayorMessage', { count: result.checkinsCount, checkinWord: checkinWord }) }}</p>
            </div>
            <div v-if="result.currentMayorNickname" class="mayor-container">
              <div class="mayor-image-container-not-current">
                <div class="circle-account" :style="{ position: 'relative' }">{{ firstLetter }}</div>
                <img class="crown-not-current" src="@/assets/mayor/crown.png" alt="Crown" />
                <p class="mayor-message">{{ $t('currentMayorMessage', { nickname: result.currentMayorNickname}) }}</p>
              </div>
            </div>

            <mayorNotification 
              v-if="showMayorNotification && result.isNewMayor"
              :isNewMayor="result.isNewMayor" 
              :previousMayorNickname="result.previousMayorNickname" 
              :currentUsername="usernameFirst"
              @close-notification="handleCloseNotification"
            />

            <div class="bonus-coins">
              
              <p class="bonus-coins-text">+ <img class="coin" src="@/assets/coin.png" alt="Coin" /> {{ result.bonusCoins }}</p>
              <p class='message-info'>{{ $t('message') }}</p>
              
            </div>
            <div class="bonus-info" @click.stop>
              <div v-if="result.placeCheckinBonus !== 0" class="bonus-coins-container">
                <p>{{ $t('check_in') }}</p>
                <div class="checkin-text"><p>+ </p><img class="coin" src="@/assets/leagueCoin.png" alt="Coin" /><p> {{ result.placeCheckinBonus }}</p></div>
              </div>
              <div v-if="result.firstCheckinBonus !== 0" class="bonus-coins-container">
                <p>{{ $t('first_checkin') }}</p>
                <div class="checkin-text"><p>+ </p><img class="coin" src="@/assets/leagueCoin.png" alt="Coin" /> <p> {{ result.firstCheckinBonus }}</p></div>
              </div>
              <div v-if="result.leagueMultiplier !== 0" class="bonus-coins-container">
                <p>{{ $t('league_bonus') }}</p>
                <p>x{{ result.leagueMultiplier + 1 }}</p>
              </div>
              <div class="bonus-coins-container">
                <p>{{ $t('streak') }}</p><p>{{ result.streak }}</p>
              </div>
              <div v-if="result.streakBonus !== 0" class="bonus-coins-container">
                <p>{{ $t('streak_bonus') }}</p><p>x{{ result.streakBonus + 1 }}</p>
              </div>
              <div v-if="result.reviewBonus !== 0" class="bonus-coins-container">
              <p>{{ $t('review_bonus') }}</p><p>x{{ result.reviewBonus + 1 }}</p>
              </div>
              <div v-if="result.boost !== 0" class="bonus-coins-container">
                <p>{{ $t('traveller') }}</p><p>x{{ result.boost + 1 }}</p>
              </div>
            </div>

          </div>
        </div>

        <div class="footer">
            <button class="close-button" @click="props.returnHome">{{ $t('close') }}</button>
            <button class="share-button" @click="shareToTelegramStory">{{ $t('share_to_story') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts" setup>
  import { defineProps, ref, onMounted, watch, computed } from 'vue';
  import { LottieAnimation } from "lottie-web-vue"
  import ConfettiJSON from '../assets/confetti.json'
  import { getLocalizedText } from '@/localizatoin';
  import mayorNotification from './mayorNotification.vue';

  let anim = ref()  

  const usernameFirst = ref('');

  const animationContainer = ref(null);
  
  const showMayorNotification = ref(false);
  
  interface Place {
    id: string;
    name: string;
    category: string;
    latitude: number;
    longitude: number;
    phoneNumber: string;
    url: string;
    address: string;
    distance: any;
  }
  
  const props = defineProps<{
    selectedPlace: Place;
    loading: boolean;
    showResult: boolean;
    result: any;
    selectedRate: number | null;
    closeModal: () => void;
    ratePlace: (rate: number) => void;
    checkIn: () => void;
    returnHome: () => void;
    message: string;
    imageSrc?: string;
  }>();
  
  const handleCloseNotification = () => {
    showMayorNotification.value = false;
  };

  const declensionOfCheckins = (count: number): string => {
    const titles = ['чекином', 'чекинами'];
    const cases = [2, 0, 1, 1, 1, 2];
    const declension = (count % 100 > 4 && count % 100 < 20) ? 1 : cases[Math.min(count % 10, 5)];
    return titles[declension === 1 ? 1 : 0];
  };

  const checkinWord = computed(() => {
    return declensionOfCheckins(props.result.checkinsCount);
  });
  const shareToTelegramStory = () => {
  if (typeof window.Telegram !== 'undefined' && window.Telegram.WebApp) {
    const localizedText = getLocalizedText('checkinMessage', {
      placeName: props.selectedPlace.name,
      bonusCoins: props.result.bonusCoins,
    });

    window.Telegram.WebApp.shareToStory(
      'https://checkiton.net/logo/logovertical.jpg', // Замените на фактический URL вашего медиафайла
      {
        text: localizedText,
      }
    );
  } else {
    console.error('Telegram WebApp API не доступен');
  }
};

const firstLetter = computed(() => {
  const username = localStorage.getItem('username');
  return username ? username.charAt(0).toUpperCase() : '';
});

  const confettiHapticPattern = async () => {
      const haptic = window.Telegram.WebApp.HapticFeedback;
      if (haptic) {
        haptic.impactOccurred("medium");
        await new Promise(resolve => setTimeout(resolve, 100));
        haptic.impactOccurred("light");
        await new Promise(resolve => setTimeout(resolve, 50));
        haptic.impactOccurred("heavy");
        await new Promise(resolve => setTimeout(resolve, 150));
        haptic.impactOccurred("light");
      } else {
        console.warn("Haptic feedback is not supported on this device.");
      }
    };

  watch(() => props.showResult, (newValue, oldValue) => {
  console.log('watch triggered: showResult changed from', oldValue, 'to', newValue);
    if (newValue) {
      console.log('Запускаю анимацию');
      confettiHapticPattern();
      console.log(props.result);
    }
  });

  const getImageSrc = (category: string) => {
    return require(`@/assets/${category.toLowerCase()}.png`);
  };

  // Наблюдатель за изменением props.result
  watch(() => props.result, (newValue) => {
    if (newValue) {
      console.log('Result updated:', newValue);
      showMayorNotification.value = newValue.isNewMayor;
      console.log('Show Mayor Notification:', showMayorNotification.value); // Debug log
    }
  });

  watch(() => props.showResult, (newValue, oldValue) => {
    console.log('watch triggered: showResult changed from', oldValue, 'to', newValue);
    if (newValue) {
      console.log('Запускаю анимацию');
      confettiHapticPattern();
      console.log(props.result);
    }
  });

  onMounted(() => {
    const storedUsername = localStorage.getItem('username')
    usernameFirst.value = storedUsername ?? '';
    console.log('Username:', usernameFirst.value); // Debug log
  });

</script>

<style scoped>

.checkin-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.checkin-info-container {
  height: calc(100vh - 166px - 194px);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
}

.result-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.message-info {
  font-size: 15px;
  font-weight: 400;
  color: var(--Native-subtitle_text_color, #aaa);
  max-width: 90%;
  text-align: center;
  overflow-wrap: break-word;
  display: inline-block;
  white-space: normal;
  margin: 0px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #171716;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 166px;
  padding: 12px 24px;
}

.selected-place-category {
  font-size: 15px;
  font-weight: 400;
  color: var(--Native-hint_color, rgba(170, 170, 170, 1));
  margin: 4px;
}

.address-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  justify-content: center;
}

.distance {
  font-size: 13px;
  font-weight: 590;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.gps-icon {
  width: 9.5px;
}

.lottie-animation {
  width: 100%;
  height: 100%;
}

.animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1002;
}

.checkin-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.coin {
  width: 16px;
  height: 16px;
}

.bonus-coins-container {
  width : 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  margin-bottom: 3px;
}

.bonus-coins-text {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 54px;
  font-weight: 400;
  color: var(--Native-text_color, #FFFFFF);
  z-index: 100;
  margin-bottom: 0px;
  margin-top: 0;
}

#result-name {
  font-size: 34px;
  max-width: 350px;
  white-space: break-spaces;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  text-align: start;
}

.selected-place-address {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}

.modal-rating-text {
  font-size: 13px;
  font-weight: 400;
  color: var(--Native-hint_color, #AAAAAA);
  margin: 0px;
}

.like-container, .dislike-container {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: var(--Custom-plain_background, #FFFFFF14);
}

.go-back {
  z-index: 100;
  margin-right: auto;
  transform: scaleX(-1);
  margin: 20px 0 0 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.checkin-icon {
  width: 24px;
  height: 24px;
}

.foreground-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 11px;
  color: white;
}

.foreground-content-result {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 11px;
  color: white;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
  margin-left: 20px;
}

.background-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: -70px;
  right: -70px;
  width: 50%;
  height: 25%;
  background-size: cover;
  background-position: 50%;
  filter: blur(20px);
  transform: scale(1.2);
  opacity: 40%;
  z-index: 1;
}

.image-container {
  width: 72px;
  height: 72px;
  margin-bottom: 10px;
  background-size: cover;
}

.place-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.modal-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 65px;
}

.bonus-coins {
  display: flex;
  flex-direction: column;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 8px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  padding: 12px;
  width: calc(100% - 40px);
}

.mayor-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  width: calc(100% - 40px);
  flex-direction: column;
  gap: 16px;
}

.mayor-image-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.mayor-image-container-not-current {
  display: flex;
  position: relative;
  align-items: center;  
  justify-content: center;
  margin-top: 20px;
}

.circle-account {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #bbb;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 26px;
  position: absolute;
  z-index: 20002;
}

.stars {
  width: 65px;
  position: absolute;
  bottom: 97px;
  z-index: 20002;
}

.layer {
  position: absolute;
  bottom: 48px;
  z-index: 20001;
  width: 110px;
  border-radius: 50%;
}

.crown {
  width: 34px;
  position: absolute;
  bottom: -5px;
  z-index: 20002;
}

.crown-not-current {
  width: 34px;
  position: absolute;
  bottom: -10px;
  z-index: 20002;
  right: -10px;
  border: 5px solid #212121;
  border-radius: 50%;
}

.mayor-image {
  position: absolute;
  bottom: 0;
  z-index: 20001;
  width: 130px;
}

.bonus-coins img {
  width: 45px;
  height: 45px;
}

h2 {
  margin-top: none;
}

.bonus-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  width: calc(100% - 40px);
  margin-top: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
}

.rating-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 8px;
}

.bonus-info p {
    margin: 0px;
}

.rating-icon {
  width: 37px;
  height: 37px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.rating-icon.selected {
  transform: scale(1.2);
}

.rating-icon:hover {
  transform: scale(1.1);
}

.modal-rating {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  align-items: center;
  margin-top: 60px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #0F0F0F;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-top: auto;
  height: 100vh;
}

#map {
  width: 100%;
  height: 250px;
  border-radius: 15px; /* Скругленные углы */
  overflow: hidden;
}

.result-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 100%;
  position: relative;
}

.make-checkin {
  width: 311px;
  height: 72px;
  background: var(--Native-button_color, #2990FF);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 10px;
  font-size: 17px;
  font-weight: 590;
  border: none;
}

.places{
  width: 98%;
  display: flex;
  flex-direction: column;
  background: #ff9a02;
  color: #ffffff;
  height: 65px;
  margin-bottom: 12px;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: center;
}

.places-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 70px;
  justify-content: center;
  width: 100%;
}

.place-name {
    font-size: 18px;
    margin-left: 15px;
}

.selected-place-name {
  z-index: 1000;
  margin-top: 16px;
  margin-bottom: 0px;
}

.place-category {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 600;
}

.rating-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff9a02;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.close-button {
  background: rgb(0, 123, 255);
}

.share-button {
  background: var(--Custom-plain_background, #FFFFFF14);
}

.close-button, .share-button {
  border: none;
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  margin-top: auto;
  height: 50px;
  margin-bottom: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center
}

</style>
