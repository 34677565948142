<template>
    <div v-if="isNewMayor" class="overlay">
      <div class="notification">
        <div v-if="previousMayorNickname">
            <div class="mayor-image-container">
                <img class="mayor-image" src="@/assets/mayor/venki.png" alt="Mayor" />
                <div class="circle-account-previous">{{ previousFirstLetter }}</div>
                <div class="circle-account-current">{{ firstLetter }}</div>
                <img class="layer" src="@/assets/mayor/layer.png" alt="Layer" />
                <img class="crown" src="@/assets/mayor/crown.png" alt="Crown" />
                <img class="stars" src="@/assets/mayor/stars.png" alt="Stars" />
            </div>
            <p class="congrats">{{ $t('congrats') }}</p>
            <p class="mayor-message">{{ $t('mayor_message_previous', {previous: previousMayorNickname}) }}</p>
            <button class="great-close" @click="closeNotification">{{ $t('great_close') }}</button>
        </div>
        <div v-else>
            <div class="mayor-image-container">
                <img class="mayor-image" src="@/assets/mayor/venki.png" alt="Mayor" />
                <div class="circle-account">{{ firstLetter }}</div>
                <img class="layer" src="@/assets/mayor/layer.png" alt="Layer" />
                <img class="crown" src="@/assets/mayor/crown.png" alt="Crown" />
                <img class="stars" src="@/assets/mayor/stars.png" alt="Stars" />
            </div>
            <p class="congrats">{{ $t('congrats') }}</p>
            <p class="mayor-message">{{ $t('mayor_message') }}</p>
            <button class="great-close" @click="closeNotification">{{ $t('great_close') }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineProps, computed, defineEmits } from 'vue';
  const emit = defineEmits(['close-notification']);
  
  const props = defineProps({
    isNewMayor: {
      type: Boolean,
      required: true
    },
    previousMayorNickname: {
      type: String,
      default: null
    },
    currentUsername: {
      type: String,
      required: true
    }
  })

  function closeNotification() {
    emit('close-notification');
  }

  const firstLetter = computed(() => {
    return props.currentUsername.charAt(0).toUpperCase();
  });

  const previousFirstLetter = computed(() => {
    return props.previousMayorNickname.charAt(0).toUpperCase();
  });

  </script>
  
<style scoped>

.circle-account-previous{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #bbb;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 26px;
    position: absolute;
    z-index: 20001;
    margin-right: 50px;
    margin-bottom: 45px;
}

.circle-account-current{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #bbb;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 26px;
    position: absolute;
    z-index: 20002;
    margin-left: 20px;
}

.great-close {
  background: #007bff;
  width: auto;
  height: auto;
  padding: 14px 24.5px;
  border-radius: 10px;
  border: none;
  font-size: 17px;
  margin-top: 24px;
  color: var(--Native-button_text_color, #FFFFFF);
  font-weight: 590;
}

.congrats {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 12px;
}

.mayor-message {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.mayor-image-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 150px;
  }

.circle-account {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #bbb;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 26px;
    position: absolute;
    z-index: 20002;
}

.mayor-image {
    position: absolute;
    bottom: 0;
    z-index: 20001;
    width: 130px;
}

.stars {
    width: 65px;
    position: absolute;
    bottom: 97px;
    z-index: 20002;
  }
  
  .layer {
    position: absolute;
    bottom: 48px;
    z-index: 20001;
    width: 110px;
    border-radius: 50%;
  }
  
  .crown {
    width: 34px;
    position: absolute;
    bottom: -5px;
    z-index: 20002;
  }  

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30000; /* Поставьте достаточно высокий z-index, чтобы быть поверх других элементов */
}

.notification {
    padding: 20px;
    border: none;
    background-color: #f9f9f9;
    margin: 10px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .05);
    background: var(--Native-bg_color, #212121);
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 388px;
}
</style>