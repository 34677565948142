<template>
    <div class="carousel">
      <ProgressBar :currentIndex="currentIndex" :total="screens.length" />
      <div class="bg-circle"></div>
      <transition name="fade" mode="out-in">
        <div :key="currentScreen.title" class="screen">
          <div v-if="currentIndex > 2" class="special">
            <div class="coming-soon">Coming soon</div>
            <h1 class="screen-title-special">{{ currentScreen.title }}</h1>
            <p class="screen-description">Place your message for the whole world to see</p>
          </div>
          <h1 v-else class="screen-title">{{ currentScreen.title }}</h1>
          <img class="screen-image" :src="currentScreen.image" alt="Screen Image" />
          <div class="gradient"></div>
          <button v-if="currentIndex < 3" @click="nextScreen()">Next</button>
          <button v-else @click="closeCarousel()">Let's go!</button>
        </div>
      </transition>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, onMounted, defineEmits } from 'vue';
  import ProgressBar from './ProgressBar.vue'; 
  
  const emit = defineEmits(['close']);
  
  const screens = ref([
    {
      title: "Welcome to Checkiton, fren!",
      image: require('@/assets/onboarding/world.png')
    },
    {
      title: "Check-in at your fav places to earn coins",
      image: require('@/assets/onboarding/main-screen.png')
    },
    {
      title: "Invite frens and turn cities into crypto mines",
      image: require('@/assets/onboarding/friends.png')
    },
    {
      title: "Shoutout to the community",
      image: require('@/assets/onboarding/world2.png')
    }
  ]);
  
  const currentIndex = ref(0);
  
  const nextScreen = () => {
    currentIndex.value = (currentIndex.value + 1) % screens.value.length;
    console.log("Current Index after nextScreen:", currentIndex.value);
  };
  
  const currentScreen = computed(() => {
    console.log("Current screen computed:", screens.value[currentIndex.value]);
    return screens.value[currentIndex.value];
  });
  
  const closeCarousel = () => {
    console.log("Close carousel triggered");
    emit('close'); // Отправка события для родительского компонента
  };
  
  // Функция для предварительной загрузки изображений
  const preloadImages = () => {
    screens.value.forEach(screen => {
      const img = new Image();
      img.src = screen.image;
    });
  };
  
  onMounted(() => {
    preloadImages();
  });
  </script>

<style scoped>

.screen-description {
  max-width: 195px;
  color: var(--Native-text_color, #FFFFFF);
  font-size: 16px;
  font-weight: 400;
  margin-top: 8px;
  text-align: center;
}

.coming-soon {
  width: 106px;
  height: 24px;
  border-radius: 24px;
  background: var(--Native-button_color, #2990FF);
  font-size: 15px;
  font-weight: 590;
  color: var(--Native-button_text_color, #FFFFFF);
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 203px;
    background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #0F0F0F 87.9%);
    z-index: 2003;
}

.bg-circle {
    position: absolute;
    width: 690px;
    height: 690px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(41, 144, 255, 0.4) 0%, rgba(41, 144, 255, 0) 100%);
    z-index: 2002;
}

.screen-image {
  min-width: 348px;
  min-height: 348px;
  margin-top: 251px;
  position: absolute;
  height: auto;
  top: 0;
  z-index: 2003;
  margin-bottom: 0px;
}

.screen-title {
    color: var(--Native-text_color, #fff);
    font-size: 28px;
    max-width: 280px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .34px;
    line-height: 34px;
    margin-top: 107px;
    z-index: 2003;
    white-space: normal;
    margin-bottom: 0px;
}

.screen-title-special {
    color: var(--Native-text_color, #fff);
    font-size: 28px;
    max-width: 280px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .34px;
    line-height: 34px;
    margin-top: 0px;
    z-index: 2003;
    white-space: normal;
    margin-bottom: 0px;
}

.special {
  margin-top: 107px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center
}

.carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    background: var(--Native-secondary_bg_color, #0F0F0F);
    overflow: hidden;
}

.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
}

button {
    background-color: #2990FF;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    z-index: 2004;
    position: absolute;
    bottom: 0;
    margin-bottom: 50px;
    height: 50px;
    width: 100%;
    width: calc(100% - 32px);
    color: var(--Native-button_text_color, #FFFFFF);
    font-size: 17px;
    font-weight: 590;
}

img {
    max-width: 80%;
    height: auto;
    margin-bottom: 20px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
