<template>
  <transition name="modal-slide-up">
    <div v-if="isVisible" class="discovery-modal-overlay" @click="closeModal">
      <div class="discovery-modal-content" ref="modal" @click.stop>
        <div class="swipe-close"></div>
        <img class="close" :src="require(`@/assets/close-modal.png`)" @click="closeModal" />
        <h2 class="discovery-title">{{ $t('addpin') }}</h2>
        <div class="emoji-scroll">
          <div class="emoji-container">
            <div class="emoji" :class="{ selected: selectedEmoji === 'like' }" @click="selectEmoji('like')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/like.png`)" 
                alt="emoji1" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'heart-eye' }" @click="selectEmoji('heart-eye')" >
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/heart-eye.png`)" 
                alt="emoji2" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'death' }" @click="selectEmoji('death')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/death.png`)" 
                alt="emoji3" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'fire' }" @click="selectEmoji('fire')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/fire.png`)" 
                alt="emoji3" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'poop' }" @click="selectEmoji('poop')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/poop.png`)" 
                alt="emoji3" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'fack' }" @click="selectEmoji('fack')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/fack.png`)" 
                alt="emoji3"   
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'ghost' }" @click="selectEmoji('ghost')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/ghost.png`)" 
                alt="emoji3" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'cold' }" @click="selectEmoji('cold')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/cold.png`)" 
                alt="emoji3" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'ufo' }" @click="selectEmoji('ufo')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/ufo.png`)" 
                alt="emoji3" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'no-hodit' }" @click="selectEmoji('no-hodit')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/no-hodit.png`)" 
                alt="emoji3" 
              />
            </div>
            <div class="emoji" :class="{ selected: selectedEmoji === 'grob' }" @click="selectEmoji('grob')">
              <img 
                class="emoji-img" 
                :src="require(`@/assets/rate/svg/grob.png`)" 
                alt="emoji3" 
              />
            </div>
          </div>
        </div>
        <div class="text-input">
        <textarea
          type="text"
          class="message-input"
          :placeholder="$t('leave_message')"
          v-model="message"
          @input="updateCharCount"
        >
        </textarea>
        <p class="char-count">{{ charCount }}/140</p>
      </div>
        <p class="pin-info">{{ $t('pinstay') }}</p>
        <div class="price-buttons">
          <button v-if="coins > 999" class="price-button" @click="submitPin">1000</button>
          <button v-else class="price-button" :style="{ background: '#7f7f7f' }">1000</button>
          <button class="price-button" @click="buyPin">$0.99</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import router from '@/router';
import axios from 'axios';
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from 'vue';

export default defineComponent({
  name: 'DiscoveryModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
  },
  emits: ['close-modal'],
  setup(props, { emit }) {
    const selectedEmoji = ref('');
    const message = ref('');
    const modal = ref<HTMLElement | null>(null);
    const charCount = ref(0);
    const maxLength = 140;
    const coins = ref(0);

    const updateCharCount = () => {
      charCount.value = message.value.length;
      if (charCount.value > maxLength) {
        message.value = message.value.slice(0, maxLength);
        charCount.value = maxLength;
      }
    };

    const closeModal = () => {
      emit('close-modal');
      enableScroll();
    };

    // Функция для создания и открытия инвойса
    const buyPin = async () => {
      console.log('Запуск функции buyPin'); // Логируем начало функции
      try {
        const response = await axios.post('/api/create-invoice', {
          amount: 0.50,
          description: 'Покупка пина на 24 часа'
        });

        const { invoiceLink } = response.data;
        console.log('Ссылка на инвойс получена:', invoiceLink); // Логируем ссылку на инвойс

        // Открываем инвойс
        window.Telegram.WebApp.openInvoice(invoiceLink);

      } catch (error) {
        console.error('Ошибка при создании ссылки для оплаты:', error);
        // Показать пользователю сообщение об ошибке
      }
    };

    const submitPin = async () => {
      const telegramId = localStorage.getItem('telegram_id');
      const payload = {
        latitude: props.latitude,
        longitude: props.longitude,
        emoji: selectedEmoji.value,
        text: message.value,
        telegram_id: telegramId,
        payment_type: 0
      };
      try {
        const response = await axios.post('/api/pins', payload);
        
        // Проверяем успешность запроса
        if (response.status === 200 || response.status === 201) {
          // Сохраняем координаты в локальное хранилище
          localStorage.setItem('lastUserPin', JSON.stringify({
            latitude: props.latitude,
            longitude: props.longitude
          }));
          
          window.location.reload();
          closeModal();
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        console.error('Failed to submit pin:', error);
      }
    };

    const handleInvoiceClosed = async (event: { slug?: any; status: any; }) => {
      console.log('Получено событие invoice_closed:', event);
      const { status } = event;

      switch (status) {
        case 'paid': {
          const telegramId = localStorage.getItem('telegram_id');
          const payload = {
            latitude: props.latitude,
            longitude: props.longitude,
            emoji: selectedEmoji.value,
            text: message.value,
            telegram_id: telegramId,
            payment_type: 1
          };
          try {
            const response = await axios.post('/api/pins', payload);
            
            // Проверяем успешность запроса
            if (response.status === 200 || response.status === 201) {
              // Сохраняем координаты в локальное хранилище
              localStorage.setItem('lastUserPin', JSON.stringify({
                latitude: props.latitude,
                longitude: props.longitude
              }));
              
              window.location.reload();
              closeModal();
            } else {
              console.error('Unexpected response status:', response.status);
            }
          } catch (error) {
            console.error('Failed to submit pin:', error);
          }
          break;
        }
        case 'cancelled':
          console.log('Оплата не была завершена');
          break;
        case 'failed':
          console.log('Оплата не удалась');
          break;
        case 'pending':
          console.log('Оплата в процессе');
          break;
        default:
          console.log('Неизвестный статус платежа:', status);
      }
    };

    const selectEmoji = (emoji: string) => {
      selectedEmoji.value = emoji;
    };

    const adjustModalPosition = () => {
      if (modal.value) {
        const viewportHeight = window.innerHeight;
        modal.value.style.bottom = `${viewportHeight / 3}px`;
      }
    };

    const resetModalPosition = () => {
      if (modal.value) {
        modal.value.style.bottom = '0px';
      }
    };

    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    };

    const enableScroll = () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };

    const getUserCoins = async () => {
      const telegramId = localStorage.getItem('telegram_id'); // Получаем telegram_id из localStorage
      // Обновляем данные о монетах в локальном хранилище
      const response = await axios.get(`/api/user_coins`, {
            params: {
              telegram_id: telegramId,
            },
          });
          if (response.data && response.data.coins !== undefined) {
            localStorage.setItem('coins', response.data.coins.toString());
            coins.value = response.data.coins;
          } else {
            console.error('No data found in response:', response.data);
          }
    }

    onMounted(async () => {

      await getUserCoins();

      selectEmoji('like');
      if (props.isVisible) {
        disableScroll();
      }
      window.addEventListener('resize', adjustModalPosition);
      window.Telegram.WebApp.onEvent('invoiceClosed', handleInvoiceClosed);
      console.log('Обработчик событий установлен'); // Логируем установку обработчика
    });

    onBeforeUnmount(() => {
      enableScroll();
      window.removeEventListener('resize', adjustModalPosition);
    });

    return {
      closeModal,
      selectedEmoji,
      selectEmoji,
      message,
      modal,
      resetModalPosition,
      submitPin,
      updateCharCount,
      charCount,
      buyPin,
      coins
    };
  },
});
</script>

<style scoped>

.emoji-scroll {
  width: 100%;
  overflow-y: auto;
  margin-bottom: 24px;
}

.swipe-close {
  width: 36px;
  height: 4px;
  border-radius: 4px;
  background: var(--Custom-divider, #FFFFFF0D);
  margin-top: 14px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.discovery-title {
  font-size: 20px;
  font-weight: 590;
  color: var(--Native-text_color, #FFFFFF);
  margin: 40px 0 20px 0;
}

.emoji-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
  width: 1200px;
}

.emoji-img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.emoji {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--Custom-plain_background, #FFFFFF14);
  display: flex;
  align-items: center;
  justify-content: center;
}

.emoji.selected {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 122, 255, 0.2) 55.5%, rgba(0, 122, 255, 0.64) 85.5%, #007AFF 100%);
}

.message-input {
  width: calc(100% - 40px);
  height: 98px;
  width: 310px;
  border-radius: 10px;
  border: none;
  background: var(--Custom-input_background, #333);
  color: var(--Native-text_color, #fff);
  margin-bottom: 20px;
  display: flex;
  outline: none;
  text-align: left;
  vertical-align: top;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  font-size: 17px;
  font-weight: 400;
  
}

.text-input {
  position: relative;
}

.char-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: var(--Custom-secondary_hint_color, #78797E);
}

.pin-info {
  font-size: 14px;
  color: var(--Native-subtitle_text_color, #AAAAAA);
  margin-bottom: 20px;
}

.price-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.price-button {
  width: 150px;
  height: 50px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 590;
  background: var(--Native-button_color, #2990FF);
  border-radius: 10px;
  border: none;
  color: var(--Native-button_text_color, #FFFFFF);
  cursor: pointer;
}

.coordinates {
  font-size: 14px;
  color: var(--Native-subtitle_text_color, #AAAAAA);
  margin-top: 20px;
}

.discovery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: opacity 0.3s ease;
  z-index: 2000;
}

.discovery-modal-content {
  background: var(--Custom-surface_primary, #171717f2);
  width: 100%;
  border-radius: 16px 16px 0 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: transform .3s ease;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 560px;
  overflow-y: auto;
}

/* Добавьте эти стили */
@media (max-height: 500px) {
  .discovery-modal-content {
    position: absolute;
    height: 100vh;
    max-height: none;
  }
}

.modal-slide-up-enter-active,
.modal-slide-up-leave-active {
  transition: all 0.3s ease;
}

.modal-slide-up-enter,
.modal-slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

button {
  outline: none;
  border: none;
  border-radius: 10px;
  font-size: 17px;
}
</style>
