<script setup>
import { getLocalizedText } from '@/localizatoin';
import { computed, defineProps } from 'vue';

const props = defineProps({
  name: String,
  coins: String,
  photo: String, // Добавили свойство photo
  league: String,
  rank: String,
  refBonus: String
})

const getLocalizedLeague = computed(() => {
  return getLocalizedText(`league_${props.league}`);
})

</script>

<template>
    <div class="refferal-header" :style="`background-color: none`">
        <img
            class="refferal-avatar"
            :src="photo ? `data:image/png;base64,${photo}` : require('@/assets/user-pic.png')"
            alt="User Photo"
        />
        <div class="refferal-info-container-container">
          <div class="refferal-info-container">
            <div class="refferal-info">
                <h1 class="refferal-name">{{ name }}</h1>
                <div class="refferal-points">
                    <img
                        class="coin"
                        :src="require('@/assets/refCoin.png')"
                        alt="Coin"
                    />
                    {{ coins }}
                </div>
            </div>
            <div 
              class="ref-league-info"
              :style="{ color: rank === '1' ? 'var(--Custom-green, #32E55E)' : '' }"
            >
              <div class="refferal-place">{{ rank === '10001' ? '10000+' : rank === '1' ? 'Top ' + rank : rank }}</div>
              <div class="refferal-league">{{ getLocalizedLeague }}</div>
            </div>
          </div>
          <hr />
        </div>  
    </div>
</template>


<style scoped>

.refferal-place {
  font-size: 17px;
}

.refferal-league {
  font-size: 15px;
  white-space: nowrap;
}

.ref-league-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 16px;
  color: var(--Native-hint_color, #AAAAAA);
}

hr {
  width: 100%;
  border: 0.5px solid var(--Custom-divider, #FFFFFF0D);
}

.refferal-info-container-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.refferal-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.refferal-header {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 7px;
  margin-left: 10px;
  padding: 0px 16px 0px 16px;

}

.refferal-header h1 {
  color: aliceblue;
  font-size: 20px
}

.refferal-avatar {
  border-radius: 50%;
  margin-right: 20px;
  width: 48px;
  height: 48px;
  object-fit: cover; /* Масштабируем изображение, чтобы оно заполнило контейнер, сохраняя пропорции */
  object-position: center; /* Центрируем изображение внутри контейнера */
  margin-bottom: 15px;
  flex-shrink: 0;
}

.refferal-name {
  font-size: 24px;
}

.coin {
  width: 13px;
}

.refferal-points {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-weight: 400; 
  color: #f0f8ff;
  align-items: center;
  font-size: 15px;
  color: var(--Native-subtitle_text_color, #AAAAAA);
}

.refferal-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  align-items: flex-start;
}

.refferal-info h1 {
  font-weight: 400;
}

p, h1, .refferal-points {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 0;
}

</style>