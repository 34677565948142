<template>
  <div v-if="waitingForOnboarding == true" class="waiting-onboarding">
    <div class="loading-ring"></div>
  </div>
  <div v-else class="container">
    <div class="user-info">
      <div class="user-header">
        
        <div class="left-side">
          
          <div class="points-container">
            <img class="coin" src="@/assets/coin.png" alt="Coin" />
            <div class="points">{{ formattedPoints }}</div>
            <div class="mayor-count-container">
              <img class="mayor-count-img" src="@/assets/mayor-count.png" alt="Coin" />
              <div class="mayor-count">{{ mayorCount }}</div>
            </div>
          </div>
        
        </div>

        <div class="right-side" @click="redirectToLeaguePage">
          <div class="user-league">
            <img class="champion-cup" src="@/assets/champion-cup.png" alt="Champion cup"/>
            {{ getLocalizedLeague }}
            <div class="user-place" >{{ formatPlace(place) }}</div>  
            <img class="stroke-img" src="@/assets/stroke.png" alt="stroke"/>            
          </div>
        </div>

      </div>
    </div>
    <keep-alive>
      <div id="map">
        <img v-if="addingPin" class="center-pin" :src="require(`@/assets/discovery/add-custom-marker.png`)" />
      </div>
    </keep-alive>
    <div class="navigation">
      <div class="user-center" @click="centerMapOnUser">
        <img class="center" :src="require(`@/assets/center.png`)"/>
      </div>
      <div v-if="!addingPin" class="add-marker">
        <button class="add-marker-button" @click="startAddingPin">
          <img class="marker" :src="require(`@/assets/marker.png`)"/>
          <p class="button-text">{{ $t('addpin') }}</p>
        </button>
      </div>
      <div v-if="addingPin" class="pin-actions">
        <button class="cancel-button" @click="cancelAddingPin">{{ $t('cancel') }}</button>
        <button class="next-button" @click="openModal">{{ $t('next') }}</button>
      </div>
    </div>
    <DiscoveryModal
      :is-visible="isModalVisible"
      :latitude="pinLocation?.latitude"
      :longitude="pinLocation?.longitude"
      @close-modal="closeModal"
    />
    <CheckinComponent
      v-model:isVisible="isCheckinVisible"
      @update-places="handleUpdatePlaces"
    />
  </div>
</template>


<script lang="ts">
import { computed, defineComponent, inject, onBeforeUnmount, onMounted, onUnmounted, Ref, ref, watch } from 'vue';
import axios from 'axios';
import DiscoveryModal from '@/components/DiscoveryModal.vue';
import CheckinComponent from '@/components/CheckinComponent.vue';
import { useRouter } from 'vue-router';
import { getLocalizedText } from '@/localizatoin';

interface Place {
  id: string;
  name: string;
  category: string;
  latitude: number;
  longitude: number;
  most_common_rate: number;
  address: string;
  iconUrl: string;
}

interface PlaceFounded {
  id: string;
  name: string;
  category: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  url: string;
  address: string;
  checkins: number;
  distance: any;
  // Добавьте другие поля, которые могут быть в объектах мест
}

interface CustomPin {
  place_id: any;
  latitude: number;
  longitude: number;
  emoji: string;
  text: string;
  bonus: number;
  checkin_count: number;
}

interface Bounds {
  northEastLat: number;
  northEastLng: number;
  southWestLat: number;
  southWestLng: number;
}

interface Position {
  top: string | 'auto';
  left: string | 'auto';
  right: string | 'auto';
  bottom: string | 'auto';
}

interface Center {
  latitude: number;
  longitude: number;
}

interface VisibleBoundsResult {
  center: Center;
  bounds: Bounds;
}

export default defineComponent({
  name: 'VisitedPlaces',
  components: { DiscoveryModal, CheckinComponent },
  setup() {
    const placesRef = ref<Place[]>([]);
    const foundedPlacesRef = ref<PlaceFounded[]>([]);
    const mayorPlacesRef = ref<Place[]>([]);
    const customPinsRef = ref<CustomPin[]>([]);
    const userLocation = ref<{ latitude: number; longitude: number } | null>(null);
    const addingPin = ref(false);
    const isModalVisible = ref(false);
    const pinLocation = ref<{ latitude: number; longitude: number }>({ latitude: 0, longitude: 0 });
    let map: any = null;
    let fetchTimeout: ReturnType<typeof setTimeout> | null = null;
    const placesFound = ref(true);
    const isCheckinVisible = inject('isCheckinComponentVisible') as Ref<boolean>;

    let previousRegion: { center: { latitude: any; longitude: any; }; span: { latitudeDelta: any; longitudeDelta: any; }; } | null = null;
    let checkRegionInterval: number | null | undefined = null;
    let animationFrameId: number;
    let isDragging = false;
    let placeAnnotations: any[] = [];
    let customAnnotations: any[] = [];
    let mayorAnnotations: any[] = [];
    let isProcessing = false;
    let groupedAnnotations: any[] = [];
    let combinedMarkers: any[] = [];
    let expandedAnnotations: any[] = [];

    const username = ref('username');
    const userPhoto = ref('');
    const mayorCount = ref(0);
    const waitingForOnboarding = ref(sessionStorage.getItem('viewedOnboarding') ? sessionStorage.getItem('viewedOnboarding') : true);
    const router = useRouter();
    const points = ref(0);
    const place = ref(0);
    const league_id = ref(0);
    const onboardingViewed = ref();
    const placesCheckin = ref<Place[]>([]);

    const centeringMapOnCombinedMarker = ref(false);

    const formattedPoints = computed(() => {
      return points.value.toLocaleString('en-US');
    });

    function animateValue(start: number, end: number, duration: number, callback: (arg0: number) => void) {
    const range = end - start;
    let startTime: number | undefined = undefined;  // Инициализируем как undefined

    function step(timestamp: number) {
      if (startTime === undefined) startTime = timestamp;  // Устанавливаем startTime при первом вызове
      const progress = Math.min((timestamp - startTime) / duration, 1);
      const currentValue = Math.floor(progress * range + start);
      callback(currentValue);

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }


    const handleUpdatePlaces = (updatedPlaces: { id: string; name: string; category: string; latitude: number; longitude: number; most_common_rate: number; address: string; iconUrl: string; }[]) => {
      placesCheckin.value = updatedPlaces;
      console.log('Updated places:', placesCheckin.value);
    };

    const checkRegionChange = () => {
      if (!map) {
        console.log('map не инициализирована');
        return;
      }

      const region = map.region;
      if (!region) {
        console.log('region не определен');
        return;
      }

      if (!previousRegion || 
          region.center.latitude !== previousRegion.center.latitude ||
          region.center.longitude !== previousRegion.center.longitude ||
          region.span.latitudeDelta !== previousRegion.span.latitudeDelta ||
          region.span.longitudeDelta !== previousRegion.span.longitudeDelta) {
        
        console.log('Обнаружено изменение региона');
        console.log('Новый регион:', region);
        console.log('Предыдущий регион:', previousRegion);
        console.log('Состояние окна чекина:', isCheckinVisible.value);

        if (isCheckinVisible.value !== true && centeringMapOnCombinedMarker.value !== true) {
          console.log('Обновляем previousRegion и вызываем updateCustomPinsOnMapMove');
          previousRegion = region;
          updateCustomPinsOnMapMove();
        } else {
          console.log('isCheckinVisible.value is true, пропускаем обновление');
        }
      }
    };

    // Функция инициализации карты
    const initMap = (token: string, lastPin: { latitude: number; longitude: number } | null) => {
      if (!window.mapkit) {
        console.error('MapKit is not loaded');
        return null;
      }

      window.mapkit.init({
        authorizationCallback: (done: (arg0: any) => void) => {
          done(token);
        },
        language: 'ru'
      });

      const map = new window.mapkit.Map('map', {
        showsCompass: window.mapkit.FeatureVisibility.Hidden,
        showsZoomControl: false,
        showsMapTypeControl: window.mapkit.FeatureVisibility.Hidden,
        showsScale: window.mapkit.FeatureVisibility.Hidden,
        mapType: window.mapkit.Map.MapTypes.MutedStandard,
        colorScheme: window.mapkit.Map.ColorSchemes.Dark,
        isZoomEnabled: true,
        isScrollEnabled: true,
        showsUserLocation: true,
        showsUserLocationControl: false,
        showsPointsOfInterest: false
      });

      map.isRotationEnabled = false;

      if (lastPin) {
        map.center = new window.mapkit.Coordinate(lastPin.latitude, lastPin.longitude);
        map.region = new window.mapkit.CoordinateRegion(
          new window.mapkit.Coordinate(lastPin.latitude, lastPin.longitude),
          new window.mapkit.CoordinateSpan(0.02, 0.02)
        );
      } else if (userLocation.value) {
        map.center = new window.mapkit.Coordinate(userLocation.value.latitude, userLocation.value.longitude);
        map.region = new window.mapkit.CoordinateRegion(
          new window.mapkit.Coordinate(userLocation.value.latitude, userLocation.value.longitude),
          new window.mapkit.CoordinateSpan(0.02, 0.02)
        );
      }

      // Добавляем обработчики событий
      map.addEventListener('drag-start', () => {
        isDragging = true;
        cancelAnimationFrame(animationFrameId);
        animationFrameId = requestAnimationFrame(updateCustomPinsOnMapMove);
      });

      map.addEventListener('drag-end', () => {
        isDragging = false;
        cancelAnimationFrame(animationFrameId);
        updateCustomPinsOnMapMove();
      });

      map.addEventListener('region-change-end', () => {
        if (fetchTimeout) {
          clearTimeout(fetchTimeout);
        }
        fetchTimeout = setTimeout(() => {
          if (isCheckinVisible.value !== true) {
            fetchPlaces();  
          }
        }, 1000);
      });

      startRegionCheck();

      return map;
    };

    const validLeagues = ['Bronze', 'Silver', 'Gold', 'Platinum'] as const;
    type League = typeof validLeagues[number];
    type LeagueKeys = `league_${League}`;

    const league: Ref<League> = ref('Bronze'); // или другое начальное значение из допустимых

    const getLocalizedLeague = computed(() => {
      const leagueKey = `league_${league.value}` as LeagueKeys;
      return getLocalizedText(leagueKey);
    });

    const mapInitialState = {
      isZoomEnabled: true,
      isScrollEnabled: true,
      isRotationEnabled: true,
    };

    // Функция для удаления всех аннотаций, кроме геопозиции пользователя
    const clearMapAnnotations = () => {
      if (map) {
        const annotationsToRemove = map.annotations.filter((annotation: { element: any; }) => {
          const annotationElement = annotation.element;
          return !annotationElement 
                  || (!annotationElement.classList.contains('user-location-annotation'));
        });
        map.removeAnnotations(annotationsToRemove);
      } else {
        console.error('Map is not initialized');
      }
    };

    // Функция для запрета зума, скролла и вращения карты
    const disableMapInteraction = () => {
      if (map) {
          console.log('Disabling map interaction'); // Логирование для проверки вызова функции
          map.isZoomEnabled = false;
          map.isScrollEnabled = false;
          map.isRotationEnabled = false;
      } else {
          console.error('Map is not initialized');
      }
    };

    // Функция для включения зума, скролла и вращения карты
    const enableMapInteraction = () => {
      if (map) {
        map.isZoomEnabled = mapInitialState.isZoomEnabled;
        map.isScrollEnabled = mapInitialState.isScrollEnabled;
        map.isRotationEnabled = mapInitialState.isRotationEnabled;
      } else {
        console.error('Map is not initialized');
      }
    };

    // Кастомная функция для центрирования карты с смещением
    const centerMapWithOffset = () => {
      console.log('Centering map with offset');
      return new Promise<void>((resolve) => {
        if (map && userLocation.value) {
          const mapHeight = map.element.clientHeight;

          // Смещение по вертикали, чтобы метка пользователя была выше центра карты
          const offsetLatitude = (map.region.span.latitudeDelta / mapHeight) * (mapHeight / 32);
          const newCenterLatitude = userLocation.value.latitude - offsetLatitude;

          map.setRegionAnimated(
            new window.mapkit.CoordinateRegion(
              new window.mapkit.Coordinate(newCenterLatitude, userLocation.value.longitude),
              new window.mapkit.CoordinateSpan(0.0025, 0.0025)
            ),
            () => {
              resolve(); // Вызываем resolve после завершения анимации
            }
          );
        } else {
          console.error('Map or user location not initialized');
          resolve();
        }
      });
    };

    watch(isCheckinVisible, (newValue) => {
      if (newValue) {  // Проверяем новое значение
          console.log('Checkin visible, starting center map');
          centerMapWithOffset();

          console.log('Centering triggered, clearing annotations');
          clearMapAnnotations();

          console.log('Annotations cleared, disabling interaction');
          disableMapInteraction();

          addAnnotationsCheckins(placesCheckin.value);

      } else {
        console.log('Checkin hidden, enabling interaction and adding pins');
        enableMapInteraction();

        updateAnnotations(customPinsRef.value, mayorPlacesRef.value);
        console.log('Добавляем аннотации placesRef.value:', placesRef.value);
        addAnnotations(placesRef.value, mayorPlacesRef.value);

        map.removeAnnotations(placesCheckin.value);
      }
    });

    const redirectToLeaguePage = () => {  
      const leagueId = league_id.value;
      const userName = username.value; // Получаем значение username
      router.push({ name: 'LeaguePage', query: { league_id: leagueId, username: userName } });
    };

    //функция для получения видимых границ
    const calculateVisibleBounds = (): VisibleBoundsResult | undefined => {
      if (!map) {
        console.error('Map is not initialized');
        return;
      }

      const region = map.region;
      if (!region) {
        console.error('Map region is undefined');
        return;
      }

      const center: Center = {
        latitude: region.center.latitude,
        longitude: region.center.longitude
      };

      const northEastLat = region.center.latitude + region.span.latitudeDelta / 2;
      const southWestLat = region.center.latitude - region.span.latitudeDelta / 2;
      const northEastLng = region.center.longitude + region.span.longitudeDelta / 2;
      const southWestLng = region.center.longitude - region.span.longitudeDelta / 2;

      const bounds: Bounds = {
        northEastLat,
        northEastLng,
        southWestLat,
        southWestLng
      };

      return { center, bounds };
    };

    const formatPlace = (place: number) => {
      const j = place % 10;
      const k = place % 100;
      if (j == 1 && k != 11) {
        return place + 'st';
      }
      if (j == 2 && k != 12) {
        return place + 'nd';
      }
      if (j == 3 && k != 13) {
        return place + 'rd';
      }
      return place + 'th';
    };

    const fetchUserData = async (telegramId: string) => {
    try {
      const response = await axios.get(`/api/get_username?telegram_id=${telegramId}`);
      if (response.data && response.data.username) {
        username.value = response.data.username;
        userPhoto.value = response.data.photo ? `data:image/png;base64,${response.data.photo}` : '';
        mayorCount.value = response.data.placesCount;
        const userData = {
          username: response.data.username,
          photo: response.data.photo,
          viewed: response.data.viewed
        };

        onboardingViewed.value = userData.viewed;
        console.log('onboardingViewed:', onboardingViewed.value);

        waitingForOnboarding.value = false;
        console.log('waitingForOnboarding:', waitingForOnboarding.value);
        
        sessionStorage.setItem('viewedOnboarding', JSON.stringify(false));
        
        sessionStorage.setItem(`user_${telegramId}`, JSON.stringify(userData));
      } else {
        console.error('Username not found in response:', response.data);
      }
    } catch (error) {
      console.error('Error fetching username:', error);
    }
    };

    function updateAnnotations(customPins: CustomPin[], mayorPlaces: any[]) {


      if (isCheckinVisible.value == true) {
        console.log('isCheckinVisible.value is true, выходим из функции');
        return;
      }

      const result = calculateVisibleBounds();
      if (!result) {
        console.error('Failed to calculate visible bounds');
        return;
      }
      const { bounds: visibleBounds, center } = result;

      const newCustomAnnotations = createAnnotations(customPins, visibleBounds, center);
      const newMayorAnnotations = createMayorAnnotations(mayorPlaces, visibleBounds, center);

      const allAnnotations = [...newCustomAnnotations, ...newMayorAnnotations];
      const groupedAnnotations = groupOverlappingMarkers(allAnnotations);

      const finalAnnotations = groupedAnnotations.map(group =>
        group.length > 1 ? createCombinedMarker(group) : group[0]
      );


      const annotationsToRemove = map.annotations.filter((annotation: { element: any; }) => {
        const annotationElement = annotation.element;
        return !annotationElement || !annotationElement.classList.contains('custom-annotation');
      });

      map.removeAnnotations(annotationsToRemove);

      map.addAnnotations(finalAnnotations);

      customAnnotations = finalAnnotations.filter(annotation =>
        !annotation.data || (!annotation.data.isMayorPlace && !annotation.data.isCombined)
      );
      mayorAnnotations = finalAnnotations.filter(annotation => annotation.data && annotation.data.isMayorPlace);
    }

    function areMarkersOverlapping(marker1: any, marker2: any, threshold = 60) {
      const coord1 = marker1.coordinate;
      const coord2 = marker2.coordinate;
      const pixelPoint1 = map.convertCoordinateToPointOnPage(coord1);
      const pixelPoint2 = map.convertCoordinateToPointOnPage(coord2);
      const dx = pixelPoint1.x - pixelPoint2.x;
      const dy = pixelPoint1.y - pixelPoint2.y;
      return Math.sqrt(dx * dx + dy * dy) < threshold;
    }

    function groupOverlappingMarkers(markers: any[]) {
      const groups: any[][] = [];
      markers.forEach(marker => {
          let addedToGroup = false;
          for (const group of groups) {
              const isSameType = group[0].data.isMayorPlace === marker.data.isMayorPlace;
              const isCustomType = !marker.data.isMayorPlace;
              
              // Проверка на пересечение и объединение кастомных меток или добавление мэрских меток к кастомным
              if (group.some(groupMarker => areMarkersOverlapping(groupMarker, marker)) && (isCustomType || isSameType)) {
                  group.push(marker);
                  addedToGroup = true;
                  break;
              }
          }
          if (!addedToGroup) {
              groups.push([marker]);
          }
      });
      return groups;
    }

    const createdAnnotations: Record<string, any> = {};

    const emojisMap: Record<string, string[]> = {}; // Хранит эмодзи для каждой группы

    function createCombinedMarker(group: any[]) {
        const result = calculateVisibleBounds();
        if (!result) {
            console.error('Failed to calculate visible bounds');
            return;
        }
        const { bounds: visibleBounds, center } = result;

        // Создаем уникальный идентификатор для группы (можно использовать координаты первой метки)
        const groupId = group.map(marker => `${marker.coordinate.latitude},${marker.coordinate.longitude}`).join('|');

         // Проверяем, существует ли уже аннотация для этой группы
        if (createdAnnotations[groupId]) {
            // Обновляем положение существующей аннотации
            const existingAnnotation = createdAnnotations[groupId];
            return existingAnnotation;
        }
        const coordinate = group[0].coordinate;
        const element = document.createElement('div');
        element.style.width = '60px';
        element.style.height = '60px';
        element.style.position = 'relative';
        element.style.display = 'flex';
        element.style.flexDirection = 'column';
        element.style.alignItems = 'center';
        element.style.justifyContent = 'center';

        // Получаем количество элементов в группе
        const groupSize = group.length;

        // Проверяем, являются ли все метки в группе мэрскими метками
        const allAreMayor = group.every(marker => marker.data.isMayorPlace);

        // Функция для создания элементов иконок мэрских меток
        function createMayorIcons(group: any[]) {
            const iconContainer = document.createElement('div');
            iconContainer.style.display = 'flex';
            iconContainer.style.justifyContent = 'center';
            iconContainer.style.alignItems = 'center';
            iconContainer.style.flexWrap = 'wrap';

            group.forEach((marker, index) => {
                const img = document.createElement('img');
                img.src = marker.iconUrl;
                img.style.width = '24px';
                img.style.height = '24px';
                img.style.margin = '0 2px';
                iconContainer.appendChild(img);
            });

            return iconContainer;
        }

        // Загрузка эмодзи
        const emojis = [
            require('@/assets/rate/svg/cold.png'),
            require('@/assets/rate/svg/death.png'),
            require('@/assets/rate/svg/fack.png'),
            require('@/assets/rate/svg/fire.png'),
            require('@/assets/rate/svg/ghost.png'),
            require('@/assets/rate/svg/grob.png'),
            require('@/assets/rate/svg/heart-eye.png'),
            require('@/assets/rate/svg/like.png'),
            require('@/assets/rate/svg/no-hodit.png'),
            require('@/assets/rate/svg/poop.png'),
            require('@/assets/rate/svg/ufo.png')
        ];

        // Функция для рандомизации эмодзи
        function getRandomEmojis(num: number) {
            const selectedEmojis = [];
            const usedIndexes = new Set();

            while (selectedEmojis.length < num) {
                const randomIndex = Math.floor(Math.random() * emojis.length);
                if (!usedIndexes.has(randomIndex)) {
                    selectedEmojis.push(emojis[randomIndex]);
                    usedIndexes.add(randomIndex);
                }
            }

            return selectedEmojis;
        }

        function getFirstEmojisFromTags(tags: any[], maxCount = 4) {
          return tags.slice(0, maxCount).map((tag: { _impl: { _data: { isMayorPlace: boolean; originalPlace: { category: string; emoji: string; }; }; }; }) => {
              const { isMayorPlace, originalPlace } = tag._impl?._data || {};
              const { category, emoji } = originalPlace || {};

              if (isMayorPlace) {
                  if (category) {
                      
                      const lowerCaseCategory = category.toLowerCase();
                      try {
                          return require(`@/assets/${lowerCaseCategory}.png`);
                      } catch (error) {
                          console.error(`Error loading image for category ${category}:`, error);
                          return null;
                      }
                  } else {
                      console.warn("Category is undefined for a mayor place marker.");
                      return null; // Или вернуть эмодзи по умолчанию
                  }
              } else if (emoji) {
                  console.log(`Attempting to load emoji: ${emoji}`);
                  try {
                      return require(`@/assets/rate/svg/${emoji}.png`);
                  } catch (error) {
                      console.error(`Error loading emoji ${emoji}:`, error);
                      return null;
                  }
              }

              console.log("No valid image or emoji found for tag:", tag);
              return null;
          }).filter(emoji => emoji !== null); // Фильтруем null значения
      }

        // Проверяем, есть ли уже сохранённые эмодзи для группы
        let selectedEmojis = emojisMap[groupId];
        if (!selectedEmojis) {
            // Предполагаем, что группа имеет свойство `tags`, которое содержит метки
            selectedEmojis = getFirstEmojisFromTags(group, 4);
            emojisMap[groupId] = selectedEmojis;
        }

        // Отображаем эмодзи в зависимости от размера группы
        const emojiContainer = document.createElement('div');
        emojiContainer.style.display = 'flex';
        emojiContainer.style.justifyContent = 'center';
        emojiContainer.style.alignItems = 'center';
        emojiContainer.style.flexWrap = 'wrap';

        if (groupSize === 2) {
            // Два эмодзи в ряд
            selectedEmojis.forEach(src => {
                const img = document.createElement('img');
                img.src = src;
                img.style.width = '24px';
                img.style.height = '24px';
                img.style.margin = '0 2px';
                emojiContainer.appendChild(img);
            });
        } else if (groupSize === 3) {
            // Три эмодзи пирамидой
            selectedEmojis.forEach((src, index) => {
                const img = document.createElement('img');
                img.src = src;
                img.style.width = '24px';
                img.style.height = '24px';
                img.style.position = 'absolute';
                if (index === 0) {
                    img.style.top = '0';
                    img.style.left = '50%';
                    img.style.transform = 'translateX(-50%)';
                } else if (index === 1) {
                    img.style.bottom = '10px';
                    img.style.left = '4px';
                } else if (index === 2) {
                    img.style.bottom = '10px';
                    img.style.right = '4px';
                }
                emojiContainer.appendChild(img);
            });
        } else if (groupSize === 4) {
            // Четыре эмодзи в виде квадрата 2x2
            selectedEmojis.forEach((src, index) => {
                const img = document.createElement('img');
                img.src = src;
                img.style.width = '24px';
                img.style.height = '24px';
                img.style.position = 'absolute';
                if (index === 0) {
                    img.style.top = '5px';
                    img.style.left = '5px';
                } else if (index === 1) {
                    img.style.top = '5px';
                    img.style.right = '5px';
                } else if (index === 2) {
                    img.style.bottom = '5px';
                    img.style.left = '5px';
                } else if (index === 3) {
                    img.style.bottom = '5px';
                    img.style.right = '5px';
                }
                emojiContainer.appendChild(img);
            });
        } else {
            // Если больше 4 меток, просто отображаем их количество
            emojiContainer.textContent = groupSize.toString();
            emojiContainer.style.color = 'aliceblue';
            emojiContainer.style.fontSize = '35px';
        }

        element.appendChild(emojiContainer);

        // Логика для стрелки
        const arrowMayorElement = document.createElement('div');
        arrowMayorElement.style.width = '12px';
        arrowMayorElement.style.height = '6px';
        arrowMayorElement.style.position = 'absolute';
        arrowMayorElement.style.transform = 'translateX(-50%)';

        // Определяем положение комбинированной метки относительно центра
        let markerPosition = 'top-left';
        if (coordinate.latitude < center.latitude) {
            if (coordinate.longitude < center.longitude) {
                markerPosition = 'bottom-left';
            } else {
                markerPosition = 'bottom-right';
            }
        } else {
            if (coordinate.longitude < center.longitude) {
                markerPosition = 'top-left';
            } else {
                markerPosition = 'top-right';
            }
        }

        // Устанавливаем положение и трансформацию стрелки в зависимости от положения метки
        switch (markerPosition) {
            case 'top-left':
                element.style.top = '70px';
                element.style.left = '10px';
                arrowMayorElement.style.top = `${-28 / 12}px`;
                arrowMayorElement.style.left = `${-28 / 12}px`;
                arrowMayorElement.style.transform = 'rotate(140deg)';
                break;
            case 'top-right':
                element.style.top = '70px';
                element.style.right = '10px';
                arrowMayorElement.style.top = `-${28 / 12}px`;
                arrowMayorElement.style.right = `-${28 / 12}px`;
                arrowMayorElement.style.left = `auto`;
                arrowMayorElement.style.bottom = `auto`;
                arrowMayorElement.style.transform = 'rotate(-140deg)';
                break;
            case 'bottom-left':
                element.style.bottom = '-50px';
                element.style.left = '10px';
                arrowMayorElement.style.bottom = `${-28 / 12}px`;
                arrowMayorElement.style.left = `${-28 / 12}px`;
                arrowMayorElement.style.transform = 'rotate(50deg)';
                break;
            case 'bottom-right':
                element.style.bottom = '-50px';
                element.style.right = '10px';
                arrowMayorElement.style.bottom = `${-28 / 12}px`;
                arrowMayorElement.style.right = `${-28 / 12}px`;
                arrowMayorElement.style.left = `auto`;
                arrowMayorElement.style.transform = 'rotate(-50deg)';
                break;
            case 'top':
                element.style.top = '70px';
                arrowMayorElement.style.bottom = `${-28 / 12}px`;
                arrowMayorElement.style.left = '50%';
                arrowMayorElement.style.transform = 'translateX(-50%) rotate(180deg)';
                break;
            case 'bottom':
                element.style.bottom = '-50px';
                arrowMayorElement.style.top = `${-28 / 12}px`;
                arrowMayorElement.style.left = '50%';
                arrowMayorElement.style.transform = 'translateX(-50%)';
                break;
            case 'left':
                element.style.left = '10px';
                arrowMayorElement.style.top = '50%';
                arrowMayorElement.style.left = `${-28 / 12}px`;
                arrowMayorElement.style.transform = 'translateY(-50%) rotate(90deg)';
                break;
            case 'right':
                element.style.right = '10px';
                arrowMayorElement.style.top = '50%';
                arrowMayorElement.style.right = `${-28 / 12}px`;
                arrowMayorElement.style.transform = 'translateY(-50%) rotate(-90deg)';
                break;
        }

        element.appendChild(arrowMayorElement);

        let mayorCount = 0;
        let customCount = 0;
        group.forEach(marker => {
            if (marker.data && marker.data.isMayorPlace) {
                mayorCount++;
            } else {
                customCount++;
            }
        });

        // Используем количество мэрских и кастомных меток для определения классов
        if (mayorCount > 0 && customCount === 0) {
            // Все метки мэрские
            element.className = 'custom-callout-mayor';
            arrowMayorElement.className = 'custom-callout-arrow-orange';
        } else if (mayorCount > 0 && customCount > 0) {
            // Смешанная группа (и мэрские, и кастомные метки)
            element.className = 'custom-callout-mixed'; // Здесь можно установить другой класс для смешанных меток, если нужно
            arrowMayorElement.className = 'custom-callout-arrow-mixed'; // Тоже можно настроить другой класс для стрелки
        } else {
            // Все метки кастомные
            element.className = 'custom-callout';
            arrowMayorElement.className = 'custom-callout-arrow';
        }
      
        if (mayorCount > 0 && customCount !== 0) {
          const bonusElement = document.createElement('div');
          bonusElement.className = 'custom-callout-bonus-merged';
          bonusElement.style.overflow = 'hidden';
          bonusElement.style.top = 'auto';
          bonusElement.style.bottom = 'auto';
          bonusElement.style.position = ''

          // Создаем флекс-контейнер
          const bonusFlexContainer = document.createElement('div');
          bonusFlexContainer.style.display = 'flex';
          bonusFlexContainer.style.alignItems = 'center';
          bonusFlexContainer.style.justifyContent = 'center';
          bonusFlexContainer.style.position = 'relative';
          bonusFlexContainer.style.width = '100%';
          bonusFlexContainer.style.height = '100%';

          // Добавляем текст с бонусом
          const bonusTextElement = document.createElement('span');
          bonusTextElement.innerText = `${mayorCount}`;
          bonusTextElement.style.position = 'relative'; // Чтобы текст был поверх фона
          bonusTextElement.style.zIndex = '2'; // Устанавливаем z-index для текста поверх фона
          bonusTextElement.style.margin = '2px';

          // Добавляем изображение
          const bonusImage = document.createElement('img');
          bonusImage.src = require('@/assets/bonus.png');
          bonusImage.alt = 'Bonus';
          bonusImage.style.width = '10px'; 
          bonusImage.style.position = 'relative'; // Чтобы изображение было поверх фона
          bonusImage.style.zIndex = '2'; // Устанавливаем z-index для изображения поверх фона
          bonusImage.style.margin = '2px';

          // Вставляем фон, текст и изображение в контейнер
          bonusFlexContainer.appendChild(bonusTextElement);
          //bonusFlexContainer.appendChild(bonusImage);

          // Вставляем флекс-контейнер в bonusElement
          bonusElement.appendChild(bonusFlexContainer);

          const messageElement = document.createElement('div');
          messageElement.className = 'custom-callout-message-mayor';

          // Создаем контейнер с флекс-расположением
          const flexContainer = document.createElement('div');
          flexContainer.style.display = 'flex';
          flexContainer.style.alignItems = 'center';
          flexContainer.style.justifyContent = 'center';
          flexContainer.style.flexDirection = 'row'; // Добавляем flexDirection для выравнивания по вертикали
          flexContainer.style.position = 'absolute';
          flexContainer.style.top = '0';
          flexContainer.style.right = '0';
          flexContainer.style.background = '#FDB900';
          flexContainer.style.borderRadius = '18px';
          flexContainer.style.padding = '1px 3px';
          // Добавляем изображение короны
          const crownImage = document.createElement('img');
          crownImage.src = require('@/assets/crown.png');
          crownImage.alt = 'Crown';
          crownImage.style.width = '17px';

          // Вставляем изображение и бонус элемент в флекс-контейнер
          flexContainer.appendChild(crownImage);
          flexContainer.appendChild(bonusElement);

          // Добавляем flexContainer в элемент
          element.appendChild(flexContainer);
        }

        const annotation = new window.mapkit.Annotation(coordinate, () => element, {
            animates: false,
            data: { originalMarkers: group }
        });

        element.addEventListener('click', () => {
            expandCombinedMarker(annotation);
        });

        // Сохраняем аннотацию в объекте для предотвращения повторного создания
        createdAnnotations[groupId] = annotation;

        return annotation;
    }

    function expandCombinedMarker(combinedMarker: { data: { originalMarkers: any; }; }) {
      const originalMarkers = combinedMarker.data.originalMarkers;

      if (originalMarkers.length > 0) {
          const firstMarker = originalMarkers[0];
          const originalPlace = firstMarker.data.originalPlace;

          if (originalPlace && originalPlace.latitude && originalPlace.longitude) {
              console.log('Центрирование карты на координатах метки из originalPlace:');
              console.log('Широта метки:', originalPlace.latitude);
              console.log('Долгота метки:', originalPlace.longitude);

              // Текущее положение карты перед изменением
              console.log('Текущие координаты карты перед центровкой:');
              console.log('Широта центра:', map.center.latitude);
              console.log('Долгота центра:', map.center.longitude);

              // Используем правильные координаты из originalPlace
              const newCenter = new window.mapkit.Coordinate(originalPlace.latitude, originalPlace.longitude);
              
              // Устанавливаем новый центр карты
              map.setCenterAnimated(newCenter, true);

              centeringMapOnCombinedMarker.value = true;

              // Ждем окончания анимации
              setTimeout(() => {
                  // Текущее положение карты после изменения
                  console.log('Текущие координаты карты после центровки:');
                  console.log('Широта центра:', map.center.latitude);
                  console.log('Долгота центра:', map.center.longitude);

                  centeringMapOnCombinedMarker.value = false;
              }, 3000);  // Увеличьте тайм-аут, если анимация занимает больше времени

          } else {
              console.error('Не удалось получить координаты метки из originalPlace');
          }
      } else {
          console.error('В объединенной метке нет оригинальных меток');
      }

      // Удаляем комбинированную метку с карты
      map.removeAnnotation(combinedMarker);
      console.log('Оригинальные метки:', originalMarkers);

      // Добавляем оригинальные метки
      map.addAnnotations(originalMarkers);

      // Добавляем оригинальные метки в expandedAnnotations для их последующего удаления
      expandedAnnotations.push(...originalMarkers);
    }

      // Создание аннотаций мэрства без их удаления
      const createMayorAnnotations = (places: any[], visibleBounds: Bounds, center: { latitude: number; longitude: number; }) => {
          // Найдем максимальный и минимальный бонус
          const maxBonus = Math.max(...places.map(place => place.bonus));
          const minBonus = Math.min(...places.map(place => place.bonus));

          return places.map(place => {
              const coordinate = new window.mapkit.Coordinate(place.latitude, place.longitude);
              const isEdge = isPinOutOfBounds(place, visibleBounds);
              let position: Position = { top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' };
              let size;

              if (isEdge) {
                  const edgePosition = calculateEdgePosition(center, place, visibleBounds, map);
                  if (edgePosition) {
                      coordinate.latitude = edgePosition.latitude;
                      coordinate.longitude = edgePosition.longitude;
                      position = edgePosition.position;
                      size = edgePosition.size;
                  }
              }

              const annotationElement = createAnnotationMayor({
                  latitude: place.latitude,
                  longitude: place.longitude,
                  emoji: place.category.toLowerCase(),
                  text: place.name,
                  bonus: place.bonus,
                  place_id: place.id,
                  checkin_count: place.checkin_count
              }, isEdge, position, maxBonus, minBonus);

              return new window.mapkit.Annotation(coordinate, annotationElement, {
                  title: '',
                  subtitle: '',
                  data: { isMayorPlace: true, originalPlace: place }
              });
          });
      };

    const updateCustomPinsOnMapMove = () => {
      console.log('updateCustomPinsOnMapMove вызвана');

      if (isCheckinVisible.value) {
        console.log('isCheckinVisible.value is true, выходим из функции');
        return;
      }

      const result = calculateVisibleBounds();
      if (!result) {
        console.error('Failed to calculate visible bounds');
        return;
      }
      console.log('Новые границы видимости:', result);

      const { bounds: visibleBounds, center } = result;

      console.log('Вызываем updateAnnotations');
      updateAnnotations(customPinsRef.value, mayorPlacesRef.value);

      if (isDragging) {
        console.log('isDragging true, запрашиваем новый анимационный кадр');
        animationFrameId = requestAnimationFrame(updateCustomPinsOnMapMove);
      }
    };

    const createAnnotations = (pins: CustomPin[], visibleBounds: Bounds, center: Center) => {
      return pins.map(pin => {
        let coordinate;
        let size;
        let position: Position = { top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' };

        if (isPinOutOfBounds(pin, visibleBounds)) {
          const edgePosition = calculateEdgePosition(center, pin, visibleBounds, map);
          if (edgePosition) {
            coordinate = new window.mapkit.Coordinate(edgePosition.latitude, edgePosition.longitude);
            position = edgePosition.position;
            size = edgePosition.size;
          } else {
            coordinate = new window.mapkit.Coordinate(pin.latitude, pin.longitude);
          }
        } else {
          coordinate = new window.mapkit.Coordinate(pin.latitude, pin.longitude);
        }

        const annotationFactory = createAnnotationFactory(pin, isPinOutOfBounds(pin, visibleBounds), position, size);
        return new window.mapkit.Annotation(coordinate, annotationFactory, {
          data: { isCustomPin: true, originalPin: pin }
        });
      }).filter(annotation => annotation !== undefined);
    };

    const addAnnotationsCheckins = (places: any[]) => {
      if (!map) {
          console.error('Карта не инициализирована');
          return;
      }

      if (places.length === 0) {
          console.warn('Нет мест для добавления аннотаций');
          return;
      }

      // Создадим новый массив аннотаций
      const newAnnotations = places.map(place => {
          const coordinate = new window.mapkit.Coordinate(place.latitude, place.longitude);

          const annotationElement = () => {
              const element = document.createElement('div');
              element.className = 'custom-annotation-checkin';

              const logo = document.createElement('img');
              logo.src = getPlaceUrl(place.category);
              logo.alt = place.name;
              logo.className = 'place-logo-checkin';
              logo.style.width = `44px`;
              logo.style.height = `44px`;

              const name = document.createElement('span');
              name.textContent = place.name;
              name.className = 'place-name-checkin';

              element.appendChild(logo);
              element.appendChild(name);

              return element;
          };

          function getPlaceUrl(category: string) {
            const lowerCaseCategory = category.toLowerCase();
            try {
              return require(`@/assets/${lowerCaseCategory}.png`);
            } catch (error) {
              console.warn(`Icon for category "${category}" not found. Using default.`);
            }
          }

          return new window.mapkit.Annotation(coordinate, annotationElement, {
              title: place.name,
              subtitle: place.name
          });
      });

      // Сравниваем новые аннотации с текущими и обновляем их
      const annotationsToAdd: any[] = [];
      const annotationsToRemove: any[] = [];

      newAnnotations.forEach(newAnnotation => {
          const existingAnnotation = placeAnnotations.find(
              annotation => annotation.title === newAnnotation.title
          );

          if (!existingAnnotation) {
              annotationsToAdd.push(newAnnotation);
          } else {
              // Обновляем позицию существующей аннотации
              existingAnnotation.coordinate = newAnnotation.coordinate;
              // Можно также обновить другие свойства, если это необходимо
          }
      });

      placeAnnotations.forEach(existingAnnotation => {
          if (!newAnnotations.find(annotation => annotation.title === existingAnnotation.title)) {
              annotationsToRemove.push(existingAnnotation);
          }
      });

      // Добавляем новые аннотации
      if (annotationsToAdd.length > 0) {
          console.log('Добавление аннотаций:', annotationsToAdd);
          map.addAnnotations(annotationsToAdd);
      }

      // Удаляем аннотации, которые больше не нужны
      if (annotationsToRemove.length > 0) {
          console.log('Удаление аннотаций:', annotationsToRemove);
          map.removeAnnotations(annotationsToRemove);
      }

      // Обновляем массив аннотаций
      placeAnnotations = newAnnotations;
      console.log('Аннотации мест обновлены на карте');
  };

  const addAnnotations = (places: any[], mayorPlaces: any[]) => {
    if (!map) {
      console.error('Карта не инициализирована');
      return;
    }

    if (places.length === 0) {
      console.warn('Нет мест для добавления аннотаций');
      return;
    }

    // Найдем максимальное и минимальное количество чекинов
    const maxCheckins = Math.max(...places.map(place => place.ratings_count));
    const minCheckins = Math.min(...places.map(place => place.ratings_count));

    console.log('Max checkins:', maxCheckins);
    console.log('Min checkins:', minCheckins);

    // Проверка, есть ли мэрская метка с такими же координатами и именем
    const isMayorPlace = (latitude: number, longitude: number, name: string): boolean => {
      return mayorPlaces.some(mayorPlace => 
        mayorPlace.latitude === latitude && 
        mayorPlace.longitude === longitude && 
        mayorPlace.name === name
      );
    };

    // Создадим новый массив аннотаций
    const newAnnotations = places
      .filter(place => !isMayorPlace(place.latitude, place.longitude, place.name)) // Исключаем обычные метки, если есть мэрская
      .map(place => {
        const coordinate = new window.mapkit.Coordinate(place.latitude, place.longitude);

        const annotationElement = () => {
          const element = document.createElement('div');
          element.className = 'custom-annotation';

          // Вычисляем размер эмодзи
          let emojiSize;
          if (place.ratings_count === maxCheckins) {
            emojiSize = 60;
          } else if (place.ratings_count === minCheckins) {
            emojiSize = 32;
          } else {
            const ratio = (place.ratings_count - minCheckins) / (maxCheckins - minCheckins);
            emojiSize = Math.round(32 + ratio * (60 - 32));
          }

          const logo = document.createElement('img');
          logo.src = place.iconUrl;
          logo.alt = place.name;
          logo.className = 'place-logo';
          logo.style.width = `${emojiSize}px`;
          logo.style.height = `${emojiSize}px`;

          const name = document.createElement('span');
          name.textContent = place.name;
          name.className = 'place-name';

          const arrowElement = document.createElement('div');
          arrowElement.className = 'custom-callout-arrow-purple';

          element.appendChild(arrowElement);
          arrowElement.style.bottom = `-8px`;
          arrowElement.style.left = '50%';
          arrowElement.style.width = `${12}px`;
          arrowElement.style.height = `${6}px`;
          arrowElement.style.transform = 'translateX(-50%)';

          element.appendChild(logo);
          element.appendChild(name);
          element.appendChild(arrowElement);

          return element;
        };

        return new window.mapkit.Annotation(coordinate, annotationElement, {
          title: place.name,
          subtitle: place.name
        });
      });

    // Сравниваем новые аннотации с текущими и обновляем их
    const annotationsToAdd: any[] = [];
    const annotationsToRemove: any[] = [];

    newAnnotations.forEach(newAnnotation => {
      const existingAnnotation = placeAnnotations.find(
        annotation => annotation.title === newAnnotation.title
      );

      if (!existingAnnotation) {
        annotationsToAdd.push(newAnnotation);
      } else {
        // Обновляем позицию существующей аннотации
        existingAnnotation.coordinate = newAnnotation.coordinate;
        // Можно также обновить другие свойства, если это необходимо
      }
    });

    placeAnnotations.forEach(existingAnnotation => {
      if (!newAnnotations.find(annotation => annotation.title === existingAnnotation.title)) {
        annotationsToRemove.push(existingAnnotation);
      }
    });

    // Добавляем новые аннотации
    if (annotationsToAdd.length > 0) {
      console.log('Добавление аннотаций:', annotationsToAdd);
      map.addAnnotations(annotationsToAdd);
    }

    // Удаляем аннотации, которые больше не нужны
    if (annotationsToRemove.length > 0) {
      console.log('Удаление аннотаций:', annotationsToRemove);
      map.removeAnnotations(annotationsToRemove);
    }

    // Обновляем массив аннотаций
    placeAnnotations = newAnnotations;
    console.log('Аннотации мест обновлены на карте');
  };

    const addCustomPins = (customPins: CustomPin[]) => {
      console.log('Добавляются кастомные пины...');
      updateAnnotations(customPins, mayorPlacesRef.value);
      console.log('Custom annotations added to the map');
    };

    const addMayorAnnotations = (places: any[]) => {
      if (!map) {
        console.error('Карта не инициализирована');
        return;
      }
      updateAnnotations(customPinsRef.value, places);
      console.log('Аннотации мест мэрства добавлены на карту');
    };

    const startRegionCheck = () => {
      if (checkRegionInterval) {
        clearInterval(checkRegionInterval);
      }
      checkRegionInterval = setInterval(checkRegionChange, 10); // Проверка каждые 100 мс
    };

    const centerMapOnUser = () => {
      if (map && userLocation.value) {
        map.setRegionAnimated(new window.mapkit.CoordinateRegion(
          new window.mapkit.Coordinate(userLocation.value.latitude, userLocation.value.longitude),
          new window.mapkit.CoordinateSpan(0.02, 0.02)
        ));
      } else {
        console.error('Map or user location not initialized');
      }
    };

    const openModal = () => {
      const centerCoordinate = map.center;
      pinLocation.value = { latitude: centerCoordinate.latitude, longitude: centerCoordinate.longitude };
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const startAddingPin = () => {
      addingPin.value = true;
    };

    const cancelAddingPin = () => {
      addingPin.value = false;
    };

    const calculateEdgePosition = (center: { latitude: number; longitude: number }, pin: { latitude: number; longitude: number }, bounds: Bounds, map: any) => {
      const mapCenter = map.center;
      const distance = getDistanceFromLatLonInKm(mapCenter.latitude, mapCenter.longitude, pin.latitude, pin.longitude);
      const maxDistance = 20; 
      const size = Math.max(30, Math.min(120, 120 - (distance / maxDistance) * 90));

      const annotationWidthPx = 120;
      const annotationHeightPx = 120;

      const topLeft = map.convertPointOnPageToCoordinate(new DOMPoint(0, 0));
      const bottomRight = map.convertPointOnPageToCoordinate(new DOMPoint(map.element.clientWidth, map.element.clientHeight));

      const latPerPx = (topLeft.latitude - bottomRight.latitude) / map.element.clientHeight;
      const lngPerPx = (bottomRight.longitude - topLeft.longitude) / map.element.clientWidth;

      const latOffset = latPerPx * (annotationHeightPx / 2);
      const lngOffset = lngPerPx * (annotationWidthPx / 2);

      let edgeLat = pin.latitude;
      let edgeLng = pin.longitude;
      let position: Position = { top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' };

      if (pin.latitude > bounds.northEastLat - latOffset) {
        edgeLat = bounds.northEastLat - latOffset;
        position.top = '20px'; // Add margin from the top
      } else if (pin.latitude < bounds.southWestLat + latOffset) {
        edgeLat = bounds.southWestLat + latOffset;
        position.bottom = '-50px'; // Add margin from the bottom if needed
      }

      if (pin.longitude > bounds.northEastLng - lngOffset) {
        edgeLng = bounds.northEastLng - lngOffset;
        position.right = '10px'; // Add margin from the right
      } else if (pin.longitude < bounds.southWestLng + lngOffset) {
        edgeLng = bounds.southWestLng + lngOffset;
        position.left = '10px'; // Add margin from the left
      }

      return { latitude: edgeLat, longitude: edgeLng, position, size };
    };

    const isPinOutOfBounds = (pin: { latitude: number; longitude: number; }, bounds: { southWestLat: number; northEastLat: number; southWestLng: number; northEastLng: number; }) => {
      return pin.latitude < bounds.southWestLat || pin.latitude > bounds.northEastLat ||
            pin.longitude < bounds.southWestLng || pin.longitude > bounds.northEastLng;
    };

    //создание кастомных меток пользователей
    const createAnnotationFactory = (pin: CustomPin, isEdge = false, position: Position = { top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' }, size?: number) => {
      return (coordinate: any, options: any) => {
        const element = document.createElement('div');
        element.className = 'custom-callout';
        
        // Вычисляем расстояние от центра карты до пина
        const mapCenter = map.center;
        const distance = getDistanceFromLatLonInKm(mapCenter.latitude, mapCenter.longitude, pin.latitude, pin.longitude);
        
        // Определяем максимальное расстояние (например, половина диагонали видимой области карты)
        const mapBounds = map.region;

        const maxDistance = 20;
        
        // Вычисляем размер метки (от 30 до 120 пикселей)
        const size = Math.max(30, Math.min(120, 120 - (distance / maxDistance) * 90));
        element.style.width = `${size}px`;
        element.style.height = `${size}px`;
        
        if (pin.emoji) {
          const emojiElement = document.createElement('img');
          emojiElement.className = 'custom-callout-img';
          emojiElement.src = require(`@/assets/rate/svg/${pin.emoji}.png`);
          emojiElement.style.width = `${size * 0.6}px`;
          emojiElement.style.height = `${size * 0.6}px`;
          element.appendChild(emojiElement);
        }
        
        if (pin.text && !isEdge) {
          const messageElement = document.createElement('div');
          messageElement.className = 'custom-callout-message';
          messageElement.innerText = pin.text;
          element.appendChild(messageElement);

          // Получаем реальную высоту элемента
          const height = messageElement.offsetHeight;

          messageElement.style.top = `-${height + 20}px`;
        }
              
        const arrowElement = document.createElement('div');
        arrowElement.className = 'custom-callout-arrow';
        element.appendChild(arrowElement);
        
        if (isEdge) {
          element.style.position = 'absolute';
          Object.assign(element.style, position);

          // Вычисляем размер и положение стрелочки в зависимости от размера метки
          const arrowSize = Math.max(10, size / 12); // Минимальный размер стрелочки 10px
          const arrowOffset = Math.max(3, size / 40); // Минимальное смещение 3px

          arrowElement.style.width = `${arrowSize}px`;
          arrowElement.style.height = `${arrowSize}px`;

          // Верх-лево
          if (position.top !== 'auto' && position.left !== 'auto') {
            element.style.top = '70px';
            element.style.left = '10px';
            arrowElement.style.top = `${-arrowOffset}px`;
            arrowElement.style.left = `${-arrowOffset}px`;
            arrowElement.style.transform = 'rotate(140deg)';
          }
          // Верх-право
          else if (position.top !== 'auto' && position.right !== 'auto') {
            element.style.top = '70px';
            element.style.right = '10px';
            arrowElement.style.top = `${-arrowOffset}px`;
            arrowElement.style.right = `${-arrowOffset}px`;
            arrowElement.style.transform = 'rotate(-140deg)';
          }
          // Низ-лево
          else if (position.bottom !== 'auto' && position.left !== 'auto') {
            element.style.bottom = '-50px';
            element.style.left = '10px';
            arrowElement.style.bottom = `${-arrowOffset}px`;
            arrowElement.style.left = `${-arrowOffset}px`;
            arrowElement.style.transform = 'rotate(50deg)';
          }
          // Низ-право
          else if (position.bottom !== 'auto' && position.right !== 'auto') {
            element.style.bottom = '-50px';
            element.style.right = '10px';
            arrowElement.style.bottom = `${-arrowOffset}px`;
            arrowElement.style.right = `${-arrowOffset}px`;
            arrowElement.style.transform = 'rotate(-50deg)';
          }
          // Остальные случаи
          else {
            if (position.left !== 'auto') {
              element.style.left = '10px';
              arrowElement.style.left = `${-arrowSize - arrowOffset}px`;
              arrowElement.style.top = '50%';
              arrowElement.style.transform = 'translateY(-50%) rotate(90deg)';
            } else if (position.right !== 'auto') {
              element.style.right = '10px';
              arrowElement.style.right = `${-arrowSize - arrowOffset}px`;
              arrowElement.style.top = '50%';
              arrowElement.style.transform = 'translateY(-50%) rotate(-90deg)';
            }
            if (position.top !== 'auto') {
              element.style.top = '70px';
              arrowElement.style.top = `${-arrowSize - arrowOffset}px`;
              arrowElement.style.left = '50%';
              arrowElement.style.transform = 'translateX(-50%) rotate(180deg)';
            } else if (position.bottom !== 'auto') {
              element.style.bottom = '-50px';
              arrowElement.style.bottom = `${-arrowSize - arrowOffset}px`;
              arrowElement.style.left = '50%';
              arrowElement.style.transform = 'translateX(-50%)';
            }
          }
        } else {
          arrowElement.style.bottom = `${-size / 12}px`;
          arrowElement.style.left = '50%';
          arrowElement.style.width = `${size / 12}px`;
          arrowElement.style.height = `${size / 12}px`;
          arrowElement.style.transform = 'translateX(-50%)';
        }

        // Добавляем обработчик клика
        element.addEventListener('click', () => {
          const coordinate = new window.mapkit.Coordinate(pin.latitude, pin.longitude);
          map.setCenterAnimated(coordinate, true);
        });
        
        return element;
      };
    };

    //создание меток мэрства для юзера
    const createAnnotationMayor = (pin: CustomPin, isEdge = false, position: Position = { top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' }, maxBonus: number, minBonus: number) => {
      return (coordinate: any, options: any) => {
        const element = document.createElement('div');
        element.className = 'custom-callout-mayor';
      
        // Вычисляем размер метки в зависимости от бонуса
        let size;
        if (maxBonus === minBonus) {
          size = 80; // Фиксированный размер, если max и min равны
        } else {
          const bonusRatio = (pin.bonus - minBonus) / (maxBonus - minBonus);
          size = Math.max(60, Math.min(100, 60 + bonusRatio * 40)); // от 60 до 100 пикселей
        }
        
        element.style.width = `${size}px`;
        element.style.height = `${size}px`;
      
  
        const emojiElement = document.createElement('img');
        emojiElement.className = 'custom-callout-img-mayor';
        emojiElement.src = require(`@/assets/${pin.emoji}.png`);
        emojiElement.style.width = `${size * 0.8}px`;
        emojiElement.style.height = `${size * 0.8}px`;
        element.appendChild(emojiElement);
      

        const arrowMayorElement = document.createElement('div');
        arrowMayorElement.className = 'custom-callout-arrow-orange';
        element.appendChild(arrowMayorElement);
        
        arrowMayorElement.style.bottom = `-13px`;
        arrowMayorElement.style.left = '50%';
        arrowMayorElement.style.width = `${size / 12}px`;
        arrowMayorElement.style.height = `${size / 12}px`;
        arrowMayorElement.style.transform = 'translateX(-50%)';
        
        if (!isEdge) {
          const messageElement = document.createElement('div');
          messageElement.className = 'custom-callout-message-mayor';

          // Создаем контейнер с флекс-расположением
          const flexContainer = document.createElement('div');
          flexContainer.style.display = 'flex';
          flexContainer.style.alignItems = 'center';
          flexContainer.style.justifyContent = 'center';

          // Добавляем изображение короны
          const crownImage = document.createElement('img');
          crownImage.src = require('@/assets/crown.png');
          crownImage.alt = 'Crown';
          crownImage.style.width = '17px';
          crownImage.style.marginRight = '2px';
  
          // Добавляем текст
          const textElement = document.createElement('span');
          textElement.innerText = pin.text;

          // Вставляем изображение и текст в контейнер
          flexContainer.appendChild(crownImage);
          flexContainer.appendChild(textElement);

          // Вставляем контейнер в messageElement
          messageElement.appendChild(flexContainer);

          // Добавляем messageElement в element
          element.appendChild(messageElement);

          // Добавляем отображение бонуса (округление вниз до целого числа)
          const roundedBonus = Math.floor(pin.bonus);

          const bonusElement = document.createElement('div');
          bonusElement.className = 'custom-callout-bonus';
          bonusElement.style.overflow = 'hidden';

          // Создаем флекс-контейнер
          const bonusFlexContainer = document.createElement('div');
          bonusFlexContainer.style.display = 'flex';
          bonusFlexContainer.style.alignItems = 'center';
          bonusFlexContainer.style.position = 'relative';
          bonusFlexContainer.style.width = '100%';
          bonusFlexContainer.style.height = '100%';

          const bonusBackground = document.createElement('div');
          bonusBackground.style.position = 'absolute';
          bonusBackground.style.top = '0';
          bonusBackground.style.left = '0';
          bonusBackground.style.right = '0';
          bonusBackground.style.bottom = '0';
          const checkinCount = pin.checkin_count || 1;
          const backgroundWidth = Math.min(((pin.bonus - roundedBonus) / checkinCount) * 100, 100);
          bonusBackground.style.width = `${backgroundWidth}%`;
          bonusBackground.style.background = '#FF8500';
          bonusBackground.style.borderRadius = '20px 0 0 20px'; // Скругление только слева
          bonusBackground.style.zIndex = '1';

          // Добавляем текст с бонусом
          const bonusTextElement = document.createElement('span');
          bonusTextElement.innerText = `+${roundedBonus}`;
          bonusTextElement.style.position = 'relative';  // Чтобы текст был поверх фона
          bonusTextElement.style.zIndex = '2'; // Устанавливаем z-index для текста поверх фона
          bonusTextElement.style.margin = '2px';

          // Добавляем изображение
          const bonusImage = document.createElement('img');
          bonusImage.src = require('@/assets/bonus.png');
          bonusImage.alt = 'Bonus';
          bonusImage.style.width = '10px'; 
          bonusImage.style.position = 'relative';  // Чтобы изображение было поверх фона
          bonusImage.style.zIndex = '2'; // Устанавливаем z-index для изображения поверх фона
          bonusImage.style.margin = '2px';

          // Вставляем фон, текст и изображение в контейнер
          bonusFlexContainer.appendChild(bonusBackground);  // Сначала добавляем фон
          bonusFlexContainer.appendChild(bonusTextElement);
          bonusFlexContainer.appendChild(bonusImage);

          // Вставляем флекс-контейнер в bonusElement
          bonusElement.appendChild(bonusFlexContainer);

          // Добавляем bonusElement в элемент
          element.appendChild(bonusElement);

          if (roundedBonus > 0) {
              bonusTextElement.innerText = `+${roundedBonus}`;
              element.style.background = 'radial-gradient(50% 50% at 50% 50%, rgba(255, 133, 0, 0) 0%, rgba(255, 133, 0, 0.16) 41.67%, rgba(255, 133, 0, 0.36) 71.67%, rgba(255, 133, 0, 0.6) 85.67%, #FF8500 100%)';
              messageElement.style.background = '#FDB900';
              messageElement.style.color = '#000000CC';
              arrowMayorElement.style.borderTop = '10px solid #FDB900';
          } else {
              bonusTextElement.innerText = `${roundedBonus}`;
              element.style.background = 'none';
              messageElement.style.background = '#6D6A61';
              messageElement.style.color = '#FFFFFF';
              arrowMayorElement.style.borderTop = '10px solid #6D6A61';
          }
        }

        if (isEdge) {

          element.style.width = `70px`;
          element.style.height = `70px`;
          emojiElement.style.width = `${70 * 0.8}px`;
          emojiElement.style.height = `${70 * 0.8}px`;

          const roundedBonus = Math.floor(pin.bonus);

          if (roundedBonus > 0) {
            const circleElement = document.createElement('div');
            circleElement.className = 'circle-element';
            element.appendChild(circleElement);
          }

          element.style.position = 'absolute';
          Object.assign(element.style, position);

          // Вычисляем размер и положение стрелочки в зависимости от размера метки
          const arrowSize = Math.max(10, 70 / 12); // Минимальный размер стрелочки 10px
          const arrowOffset = Math.max(3, 70 / 40); // Минимальное смещение 3px

          arrowMayorElement.style.width = `${arrowSize}px`;
          arrowMayorElement.style.height = `${arrowSize}px`;

          // Верх-лево
          if (position.top !== 'auto' && position.left !== 'auto') {
            element.style.top = '70px';
            element.style.left = '10px';
            arrowMayorElement.style.top = `${-arrowOffset}px`;
            arrowMayorElement.style.left = `${-arrowOffset}px`;
            arrowMayorElement.style.transform = 'rotate(140deg)';
          }
          // Верх-право
          else if (position.top !== 'auto' && position.right !== 'auto') {
            element.style.top = '70px';
            element.style.right = '10px';
            arrowMayorElement.style.top = `${-arrowOffset}px`;
            arrowMayorElement.style.right = `${-arrowOffset}px`;
            arrowMayorElement.style.transform = 'rotate(-140deg)';
          }
          // Низ-лево
          else if (position.bottom !== 'auto' && position.left !== 'auto') {
            element.style.bottom = '-50px';
            element.style.left = '10px';
            arrowMayorElement.style.bottom = `${-arrowOffset}px`;
            arrowMayorElement.style.left = `${-arrowOffset}px`;
            arrowMayorElement.style.transform = 'rotate(50deg)';
          }
          // Низ-право
          else if (position.bottom !== 'auto' && position.right !== 'auto') {
            element.style.bottom = '-50px';
            element.style.right = '10px';
            arrowMayorElement.style.bottom = `${-arrowOffset}px`;
            arrowMayorElement.style.right = `${-arrowOffset}px`;
            arrowMayorElement.style.transform = 'rotate(-50deg)';
          }
          // Остальные случаи
          else {
            if (position.left !== 'auto') {
              element.style.left = '10px';
              arrowMayorElement.style.left = `${-arrowSize - arrowOffset}px`;
              arrowMayorElement.style.top = '50%';
              arrowMayorElement.style.transform = 'translateY(-50%) rotate(90deg)';
            } else if (position.right !== 'auto') {
              element.style.right = '10px';
              arrowMayorElement.style.top = '50%';
              arrowMayorElement.style.right = `${-arrowSize - arrowOffset}px`;
              arrowMayorElement.style.left = 'auto';
              arrowMayorElement.style.bottom = 'auto';
              arrowMayorElement.style.transform = 'translateY(-50%) rotate(-90deg)';
            }
            if (position.top !== 'auto') {
              element.style.top = '70px';
              arrowMayorElement.style.top = `${-arrowSize - arrowOffset}px`;
              arrowMayorElement.style.left = '50%';
              arrowMayorElement.style.transform = 'translateX(-50%) rotate(180deg)';
            } else if (position.bottom !== 'auto') {
              element.style.bottom = '-50px';
              arrowMayorElement.style.bottom = `${-arrowSize - arrowOffset}px`;
              arrowMayorElement.style.left = '50%';
              arrowMayorElement.style.transform = 'translateX(-50%)';
            }
          }
        } else {
          arrowMayorElement.style.bottom = `${-size / 12}px`;
          arrowMayorElement.style.left = '50%';
          arrowMayorElement.style.width = `${size / 12}px`;
          arrowMayorElement.style.height = `${size / 12}px`;
          arrowMayorElement.style.transform = 'translateX(-50%)';
        }

        // Добавляем обработчик клика
        element.addEventListener('click', async () => {
        if (isProcessing) return;
        isProcessing = true;

        const coordinate = new window.mapkit.Coordinate(pin.latitude, pin.longitude);
        map.setCenterAnimated(coordinate, true);

        const telegramId = localStorage.getItem('telegram_id');

        try {
          const response = await fetch('/api/get-mayor-bonus', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              telegram_id: telegramId,
              place_id: pin.place_id
            })
          });

          const data = await response.json();

          if (response.ok && data.success) {
            const oldPoints = points.value;
            const newPoints = points.value + data.bonus;

            // Запускаем анимацию изменения числа
            animateValue(oldPoints, newPoints, 1000, (value) => {
              points.value = value;
            });
          } else {
            console.warn(data.message || 'Ошибка получения бонуса');
          }
        } catch (error) {
          console.error('Ошибка запроса:', error);
        } finally {
          isProcessing = false;
        }
      });
      
        return element;
      };
    };

    // Функция для вычисления расстояния между двумя точками на сфере (в км)
    function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a = 
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ; 
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
      const d = R * c; // Distance in km
      return d;
    }

    function deg2rad(deg: number) {
      return deg * (Math.PI / 180);
    }

    const fetchMayorPlaces = async (telegram_id: any, radiusKm: number | null | undefined ) => {
      
      if (radiusKm === undefined) {
        radiusKm = 0; // значение по умолчанию
      }
      
      try {
        let params = {};
        if (radiusKm === null) {
          // Если радиус не указан, используем координаты всей Земли
          params = {
            northEastLat: 90,
            northEastLng: 180,
            southWestLat: -90,
            southWestLng: -180
          };
        } else {
          const region = map.region;
          if (!region) throw new Error('Map region is undefined');

          const centerLat = region.center.latitude;
          const centerLng = region.center.longitude;

          const bounds = getBounds(centerLat, centerLng, radiusKm);
          params = {
            northEastLat: bounds.northEastLat,
            northEastLng: bounds.northEastLng,
            southWestLat: bounds.southWestLat,
            southWestLng: bounds.southWestLng
          };
        }

        console.log('Fetching mayor places with bounds:', params);

        const response = await axios.post('/api/check-mayor', {
          telegram_id,
          ...params
        });

        if (response.data && response.data.success) {
          const mayorPlaces = response.data.places.map((place: {
            category: string;
            id: any;
            name: any;
            latitude: any;
            longitude: any;
            address: any;
            bonus: number | string;
            checkin_count: number;
          }) => {
            const iconUrl = getCategoryIcon(place.category);
            return {
              id: place.id,
              name: place.name,
              category: place.category,
              latitude: place.latitude,
              longitude: place.longitude,
              address: place.address,
              iconUrl,
              bonus: typeof place.bonus === 'number' ? parseFloat(place.bonus.toFixed(2)) : parseFloat(place.bonus),
              checkinCount: place.checkin_count
            };
          });

          mayorPlacesRef.value = mayorPlaces;
          addMayorAnnotations(mayorPlacesRef.value);
        } else {
          console.log('No mayor places found');
        }
      } catch (error) {
        console.error('Error fetching mayor places:', error);
      }
    };

    const getCategoryIcon = (category: string) => {
      const lowerCaseCategory = category.toLowerCase();
      try {
        return require(`@/assets/${lowerCaseCategory}.png`);
      } catch (error) {
        console.warn(`Icon for category "${category}" not found. Using default.`);
      }
    };

    const fetchPlaces = async () => {
      try {
        const region = map.region;
        if (!region) throw new Error('Map region is undefined');

        const northEastLat = region.center.latitude + region.span.latitudeDelta / 2;
        const southWestLat = region.center.latitude - region.span.latitudeDelta / 2;
        const northEastLng = region.center.longitude + region.span.longitudeDelta / 2;
        const southWestLng = region.center.longitude - region.span.longitudeDelta / 2;

        const bounds = {
          northEastLat,
          northEastLng,
          southWestLat,
          southWestLng,
        };

        console.log('Fetching places with bounds:', bounds);

        const response = await axios.get('/places', { params: bounds });

        if (response.data) {
          const places = response.data.map((place: { most_common_rate: number; iconUrl: any; name: any; }) => {
            const rateIcon = getRateIcon(place.most_common_rate);
            place.iconUrl = require(`@/assets/rate/${rateIcon}.png`);
            return place;
          });

          const telegramId = localStorage.getItem('telegram_id');

          placesRef.value = places;
          addAnnotations(placesRef.value, mayorPlacesRef.value);
          fetchUserPins(10000);
          fetchMayorPlaces(telegramId, 2000);
        } else {
          console.log('No places found');
        }
      } catch (error) {
        console.error('Error fetching places:', error);
      }
    };

    const fetchUserPins = async (radiusKm: number | null | undefined) => {
      if (!map) return;

      if (radiusKm === undefined) {
        radiusKm = 0; // значение по умолчанию
      }

      try {
        let params = {};
        if (radiusKm === null) {
          // Если радиус не указан, используем координаты всей Земли
          params = {
            northEastLat: 90,
            northEastLng: 180,
            southWestLat: -90,
            southWestLng: -180
          };
        } else {
          const region = map.region;
          if (!region) throw new Error('Map region is undefined');

          const centerLat = region.center.latitude;
          const centerLng = region.center.longitude;

          const bounds = getBounds(centerLat, centerLng, radiusKm);
          params = {
            northEastLat: bounds.northEastLat,
            northEastLng: bounds.northEastLng,
            southWestLat: bounds.southWestLat,
            southWestLng: bounds.southWestLng
          };
        }

        const response = await axios.get('/user-pins', { params });

        if (response.data) {
          let customPins = response.data;

          if (!Array.isArray(customPins)) {
            console.error('Expected an array but received:', customPins);
            customPins = [];
          }

          customPinsRef.value = customPins;
          addCustomPins(customPinsRef.value);
        } else {
          console.log('No custom pins found');
        }
      } catch (error) {
        console.error('Error fetching user pins:', error);
      }
    };

    const getBounds = (lat: number, lng: number, radiusKm: number) => {
      const earthRadiusKm = 6371;

      const northEastLat = lat + (radiusKm / earthRadiusKm) * (180 / Math.PI);
      const southWestLat = lat - (radiusKm / earthRadiusKm) * (180 / Math.PI);
      const northEastLng = lng + (radiusKm / earthRadiusKm) * (180 / Math.PI) / Math.cos(lat * Math.PI / 180);
      const southWestLng = lng - (radiusKm / earthRadiusKm) * (180 / Math.PI) / Math.cos(lat * Math.PI / 180);

      return {
        northEastLat,
        northEastLng,
        southWestLat,
        southWestLng
      };
    };

    const getRateIcon = (rate: number) => {
      switch (rate) {
        case 1: return 'fire';
        case 2: return 'heart';
        case 3: return 'poop';
        case 4: return 'heze';
        default: return 'default';
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      // Предотвращаем стандартное поведение прокрутки
      event.preventDefault();
      
      // Если карта инициализирована, передаем событие карте
      if (map) {
        const touch = event.touches[0];
        const newEvent = new TouchEvent('touchmove', {
          bubbles: true,
          cancelable: true,
          view: window,
          touches: [touch],
          targetTouches: [touch],
          changedTouches: [touch]
        });
        map.dispatchEvent(newEvent);
      }
    };

    const fetchLeaderboardData = async (telegramId: string) => {
      try {
        const response = await axios.get(`/api/leaderboard/${telegramId}`);
        if (response.data) {
          points.value = response.data.coins;
          place.value = response.data.rank;
          league.value = response.data.league;
          league_id.value = response.data.league_id;
        } else {
          console.error('No data found in response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    };

    function waitForMapKit() {
      return new Promise((resolve, reject) => {
        if (window.mapkit) {
          resolve(window.mapkit);
        } else {
          const checkMapKit = setInterval(() => {
            if (window.mapkit) {
              clearInterval(checkMapKit);
              resolve(window.mapkit);
            }
          }, 100);

          // Устанавливаем таймаут на случай, если MapKit так и не загрузится
          setTimeout(() => {
            clearInterval(checkMapKit);
            reject(new Error("MapKit is not loaded"));
          }, 10000); // 10 секунд на загрузку MapKit
        }
      });
    }

    onBeforeUnmount(() => {
      if (map) {
        map.destroy(); // Уничтожаем карту, чтобы избежать утечек памяти и проблем с повторной инициализацией
        map = null;
      }
    });

    onUnmounted(() => {
      const mapElement = document.getElementById('map');
      if (mapElement) {
        mapElement.removeEventListener('touchmove', handleTouchMove);
      }
    });

    onMounted(async () => {

      try {
        await waitForMapKit();  // Ждем загрузки MapKit
      } catch (error) {
        console.error(error);
        return;
      }

      // Telegram WebApp API готов
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.BackButton.hide();

      const userLanguage = window.Telegram.WebApp.initDataUnsafe.user?.language_code;
      localStorage.setItem('user_language', userLanguage);

      const telegramId = localStorage.getItem('telegram_id');
      const usernameStart = localStorage.getItem('username');
      const refferalCode = localStorage.getItem('invited_by');

      if (!telegramId || !usernameStart) {
        console.error('No telegram_id or username found');
        return;
      }

      try {
        const response = await axios.get('/api/check_user', {
          params: {
            telegram_id: telegramId,
            username: usernameStart,
            referral_code: refferalCode,
          },
        });

        if (response.data.status === 'success') {
          await fetchUserData(telegramId);
          await fetchLeaderboardData(telegramId);
        } else {
          console.error('User check failed:', response.data.message);
        }
      } catch (error) {
        console.error('Error checking user:', error);
      }

      const mapElement = document.getElementById('map');
      if (mapElement) {
        mapElement.addEventListener('touchmove', handleTouchMove, { passive: false });
      }

      // Проверяем, была ли карта уже инициализирована
      if (map === null) {
        try {
          const response = await fetch('/get-mapkit-token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          const data = await response.json();
          const token = data.token;

          // Определяем тип для lastPin
          type LastPin = { latitude: number; longitude: number } | null;

          // Проверяем наличие последней метки в локальном хранилище
          const lastPinString = localStorage.getItem('lastUserPin');
          let lastPin: LastPin = null;
          if (lastPinString) {
            lastPin = JSON.parse(lastPinString);
            localStorage.removeItem('lastUserPin');
          }

          // Получаем текущую геопозицию пользователя, если она еще не была получена
          if (!userLocation.value) {
            navigator.geolocation.getCurrentPosition(async (position) => {
              userLocation.value = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              };
              map = initMap(token, lastPin);  // Инициализация карты только при первой загрузке
              fetchPlaces();  // Загрузка мест после инициализации карты
            }, (error) => {
              userLocation.value = { latitude: 55.751244, longitude: 37.618423 }; // Default location
              map = initMap(token, lastPin);
              fetchPlaces();
            });
          } else {
            // Если геопозиция уже известна, просто инициализируем карту
            map = initMap(token, lastPin);
            fetchPlaces();
          }
        } catch (error) {
          console.error('Error during map initialization:', error);
        }
      } else {
        // Карта уже инициализирована, просто обновляем её состояние
        if (userLocation.value) {
          map.setCenter(new window.mapkit.Coordinate(userLocation.value.latitude, userLocation.value.longitude));
          map.setRegion(new window.mapkit.CoordinateRegion(
            new window.mapkit.Coordinate(userLocation.value.latitude, userLocation.value.longitude),
            new window.mapkit.CoordinateSpan(0.02, 0.02)
          ));
        }
        fetchPlaces();
      }
    });

    return {
      placesRef,
      customPinsRef,
      centerMapOnUser,
      startAddingPin,
      cancelAddingPin,
      addingPin,
      isModalVisible,
      closeModal,
      openModal,
      pinLocation,
      mayorPlacesRef,
      waitingForOnboarding,
      userPhoto,
      username,
      redirectToLeaguePage,
      getLocalizedLeague,
      formatPlace,
      place,
      formattedPoints,
      isCheckinVisible,
      mayorCount,
      handleUpdatePlaces
    };
  }
});
</script>

<style scoped>
.mayor-count-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-left: 26px;
}

.mayor-count-img{
  width: 28px;
  height: 28px;
}

.mayor-count{
  font-size: 17px;
  font-weight: 400;
  color: white;
}

.user-avatar-placeholder {
  width: 24px;
  height: 24px;
  background-color: #ccc; /* Цвет фона подберите по своему вкусу */
  border-radius: 50%; /* Скругление, чтобы был круг */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px; /* Размер шрифта подберите по своему вкусу */
  color: white; /* Цвет текста */
  margin-right: 8px;
}

.points-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.points {
  color: aliceblue;
  font-size: 17px;
  font-weight: 400;
}

.user-name {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255 255 255 / 0.08);
  padding: 4px 12px;
  border-radius: 50px;
}

.champion-cup {
  width: 22px;
  height: 22px;
}

.user-league {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: aliceblue;
  font-size: 17px;
  font-weight: 510;
  align-items: center;
  gap: 8px;
}

.stroke-img {
  height: 12px;
  margin-left: 4px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.user-place {
  display: flex;
  justify-content: flex-end;
  font-size: 17px;
  font-weight: 400;
  color: #AAAAAA;
  text-decoration: none;
}

.user-header, .user-bottom {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.left-side{
  display: flex;
  flex-direction: column;
}

.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255 255 255 / 0.08);
  padding: 4px 12px;
  border-radius: 50px;
}

.user-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-avatar {
  border-radius: 50%;
  margin-right: 8px;
  width: 24px;
  height: 24px; /* Добавляем высоту для квадратного контейнера */
  object-fit: cover; /* Масштабируем изображение, чтобы оно заполнило контейнер, сохраняя пропорции */
  object-position: center; /* Центрируем изображение внутри контейнера */
}

.user-name {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

.user-head h1 {
  color: aliceblue;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 80px;
}

/* Стили для карты */
#map {
  width: 100%;
  height: 100vh;
  position: relative;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
}

/* Стили для центрального пина */
.center-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  pointer-events: none;
  width: 123px;
  height: 123px;
  z-index: 20;
}

/* Стили для навигации */
.navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 7px;
  padding-right: 7px;
  margin-top: 10px;
  z-index: 1; 
  position: fixed;
  bottom: 110px;
}

/* Стили для кнопки центрирования карты */
.user-center {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background: #FFFFFF1F;
}

.center {
  width: 21.9px;
  height: 21.9px;
}

/* Стили для кнопки добавления маркера */
.add-marker-button {
  width: 174px;
  height: 50px;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 590;
  background: var(--Native-button_color, #2990FF);
  border-radius: 10px;
  border: none;
  color: var(--Native-button_text_color, #FFFFFF);
}

.marker {
  width: 15.75px;
}

.button-text {
  margin: 0;
}

/* Стили для действий с пином */
.pin-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.coin{
  width: 28px;
  border-radius: 50%; /* чтобы изображение стало круглым */
}

.cancel-button, .next-button {
  width: 134px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 17px;
  color: var(--Native-button_text_color, #FFFFFF);
  font-weight: 590;
  border: none;
  backdrop-filter: blur(2px);
}

.cancel-button {
  background: var(--Custom-plain_background, #FFFFFF14);
}

.next-button {
  background: var(--Native-button_color, #2990FF);
}

/* Стили для модального окна */
.discovery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: opacity 0.3s ease;
  z-index: 2000;
}

.discovery-modal-content {
  background: var(--Custom-surface_primary, #171717F2);
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 16px 16px 0 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.modal-slide-up-enter-active,
.modal-slide-up-leave-active {
  transition: all 0.3s ease;
}

.modal-slide-up-enter,
.modal-slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.swipe-close {
  width: 36px;
  height: 4px;
  border-radius: 4px;
  background: var(--Custom-divider, #FFFFFF0D);
  margin-top: 14px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.discovery-title {
  font-size: 20px;
  font-weight: 590;
  color: var(--Native-text_color, #FFFFFF);
  margin: 40px 0 20px 0;
}

.emoji-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.emoji {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.emoji.selected {
  border: 2px solid #2990FF;
  border-radius: 50%;
}

.message-input {
  width: calc(100% - 40px);
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--Custom-border_color, #ccc);
  background: var(--Custom-input_background, #333);
  color: var(--Native-text_color, #FFFFFF);
  margin-bottom: 20px;
}

.pin-info {
  font-size: 14px;
  color: var(--Native-subtitle_text_color, #AAAAAA);
  margin-bottom: 20px;
}

.price-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.price-button {
  width: 150px;
  height: 50px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 590;
  background: var(--Native-button_color, #2990FF);
  border-radius: 10px;
  border: none;
  color: var(--Native-button_text_color, #FFFFFF);
  cursor: pointer;
}

.coordinates {
  font-size: 14px;
  color: var(--Native-subtitle_text_color, #AAAAAA);
  margin-top: 20px;
}

</style>