<template>
  <div :id="buttonRootId" :class="buttonClass" :style="buttonStyle"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, computed } from 'vue';
import { useTonConnectUI } from '../hooks/useTonConnectUI';

const buttonRootId = 'ton-connect-button';

export default defineComponent({
  name: 'TonConnectButton',
  props: {
    className: String,
    style: Object
  },
  setup(props) {
    const { setOptions, tonConnectUI } = useTonConnectUI();

    onMounted(() => {
      if (tonConnectUI.value) {
        console.log('TonConnectUI is available, setting options');
        setOptions({ buttonRootId });
      } else {
        console.error('TonConnectUI is not available.');
      }
    });

    onUnmounted(() => {
      setOptions({ buttonRootId: null });
    });

    const buttonClass = computed(() => props.className);
    const buttonStyle = computed(() => props.style);

    return {
      buttonRootId,
      buttonClass,
      buttonStyle
    };
  }
});
</script>

